import moment from 'moment';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestPost } from '../../../../utils/requestHelper';

export default class MethodVideo extends Component {
    constructor(props) {
        super(props);
        this.id = "";
    }

    editStadium() {
        const body = {
            id: this.id || "",
            url: this.refs[`url${this.props.id}`].value,
            typeImage: this.props.typeImage
        }
        let urlRequest = "";
        urlRequest = `${DOMAIN_MAIN}/banners/edit/url`;
        apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
            else {
                NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
                this.props.getbannerList();
                this.refs[`url${this.props.id}`].value = "";
            }
        });
    }
    rendermodalEditVideoMethod() {
        return (
            <div className="modal show" id={this.props.id || "modalEditVideoMethod"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chỉnh sửa phương pháp</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea12">Đường dẫn</label>
                                <input ref={`url${this.props.id}`} className="form-control" id="exampleFormControlTextarea12345" rows="3"></input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.editStadium.bind(this)} data-dismiss="modal">Chỉnh sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }



    render() {
        return (
            <>
                {this.rendermodalEditVideoMethod()}
                <NotificationContainer />


                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <h5>{this.props.title}</h5>
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Đường dẫn</th>
                                        <th>Ngày Tạo</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td><a href={this.props.bannerList.url} target="_blank">{this.props.bannerList.url}</a></td>
                                        <td>{moment(this.props.bannerList.createdAt).format("DD-MM-YYYY")}</td>
                                        <td>
                                            <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target={`#${this.props.id}` || "#modalEditVideoMethod"} onClick={() => {
                                                this.refs[`url${this.props.id}`].value = this.props.bannerList.url || "";
                                                this.id = this.props.bannerList._id;
                                            }}>Chỉnh Sửa</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}