import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import moment from 'moment';
import _ from 'lodash';
import DatePicker from "react-datepicker";

export default class DetailPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentList: {},
            listSchedule: [],
            chooseDetail: {},
            chargeInfo: {},
            dateCharge: new Date()
        };
        this.round = 0;
    }

    handleChangeDateCharge = date => {
        this.setState({
            dateCharge: date
        });
    };

    handleListPayment(paymentList) {
        // let currentDate = new Date(moment(new Date()));
        let rollCall = paymentList.rollCall;
        const countPhase = Math.ceil(paymentList.rollCall.length / paymentList.classId.numberLessonToCharge)
        let rollCallResult = [];
        for (let i = 0; i < countPhase + 3; i++) {
            let payment = paymentList.payment.filter(p => { return p.round === i });
            const rollCallTmp = rollCall.slice(i * paymentList.classId.numberLessonToCharge, (i + 1) * paymentList.classId.numberLessonToCharge)
            const data = {
                rollCall: rollCallTmp,
                startDate: rollCallTmp[0],
                endDate: rollCallTmp[paymentList.classId.numberLessonToCharge - 1],
                payment
            }
            rollCallResult.push(data);
        }
        this.setState({
            listSchedule: rollCallResult
        })
        // code cũ
        // if (rollCall.length > 0) {
        //     rollCall = rollCall.sort(function (a, b) {
        //         return new Date(a) - new Date(b);
        //     });
        //     const paymentFirstDay = new Date(moment(rollCall[0]));
        //     let numberSchedule = Math.ceil(moment(currentDate).diff(moment(paymentFirstDay), "days") / 28) + 3;
        //     let paymentDateTemp = paymentFirstDay;
        //     let rollCallResult = [];
        //     for (let i = 0; i < numberSchedule; i++) {
        //         let paymentDate = new Date(paymentDateTemp);
        //         let paymentDateTempNext = new Date(paymentDateTemp.setDate(paymentDateTemp.getDate() + 28));

        //         let isCheck = paymentList.payment.filter(p => { return p.round === i });
        //         if (isCheck) {
        //             rollCallResult.push({
        //                 rollCall: rollCall.filter(rc => {
        //                     return new Date(rc).getTime() >= paymentDate.getTime() && new Date(rc).getTime() <= paymentDateTempNext.getTime()
        //                 }),
        //                 startDate: paymentDate,
        //                 endDate: paymentDateTempNext,
        //                 payment: isCheck
        //             });
        //         } else {
        //             rollCallResult.push({
        //                 rollCall: rollCall.filter(rc => {
        //                     return new Date(rc).getTime() >= paymentDate.getTime() && new Date(rc).getTime() <= paymentDateTempNext.getTime()
        //                 }),
        //                 startDate: paymentDate,
        //                 endDate: paymentDateTempNext,
        //                 payment: []
        //             });
        //         }
        //         paymentDateTemp = new Date(paymentDateTempNext);
        //     }
        //     this.setState({
        //         listSchedule: rollCallResult
        //     })
        // }
    }

    getPaymentList(studentId) {
        let urlRequest = `${DOMAIN_MAIN}/students/payment/detail/${studentId}`;
        apiRequestGetAuthen(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                result.classInfo.rollCall.sort((a, b) => { return new Date(a) - new Date(b) })
                this.setState({ paymentList: result });
                this.handleListPayment(result.classInfo)
            }
        });
    }

    chargeLesson(studentId) {
        const { state } = this;
        const body = {
            studentId,
            round: state.chargeInfo.round,
            charge: this.refs['moneyCharge'].value,
            date: state.dateCharge,
            totalCharge: this.refs['totalCharge'].value,
            note: this.refs['note'].value
        }
        let urlRequest = `${DOMAIN_MAIN}/students/charge`;
        apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Không thể thanh toán", 'Lỗi', 2000);
            else {
                this.getPaymentList(this.props.match.params.studentId);
                NotificationManager.success("Thanh toán thành công", 'Thành công', 2000);
            }
        });
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.studentId !== nextProps.match.params.studentId) {
            this.getPaymentList(nextProps.match.params.studentId);
        }
    }

    componentDidMount() {
        this.getPaymentList(this.props.match.params.studentId);
    }

    renderModalDetailCharge() {
        const { state } = this;
        return (
            <div className="modal" id="modalDetailCharge">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chi tiết học phí</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="row margin-top">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Ngày đóng</th>
                                                            <th>Số tiền</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.chooseDetail.payment && state.chooseDetail.payment.map((p, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{moment(p.date).format("DD-MM-YYYY")} - {p.note}</td>
                                                                    <td>{p.charge}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row margin-top">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Ngày học</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.chooseDetail.rollCall && state.chooseDetail.rollCall.map((ls, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{moment(ls).format("DD-MM-YYYY")}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderModalCharge() {
        const { state, props } = this;
        return (
            <div className="modal" id="modalCharge">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thanh toán học phí</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="input-select">Bắt đầu</label>
                                <input id="inputText3" type="text" className="form-control" disabled={true} value={state.chargeInfo.startDate ? state.chargeInfo.startDate : ""} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="input-select">Kết thúc</label>
                                <input id="inputText3" type="text" className="form-control" disabled={true} value={state.chargeInfo.endDate ? state.chargeInfo.endDate : ""} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="input-select">Số tiền đóng</label>
                                <input id="inputText3" type="number" className="form-control" ref="moneyCharge" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="input-select">Tổng số tiền cần đóng</label>
                                <input id="inputText3" type="number" className="form-control" ref="totalCharge" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="input-select">Cách thức nộp tiền</label>
                                <input id="inputText3" type="text" className="form-control" ref="note" />
                            </div>
                            <div className="container-datepicker">
                                <label htmlFor="input-select">Ngày thu tiền</label>
                                <DatePicker
                                    selected={state.dateCharge}
                                    onChange={this.handleChangeDateCharge}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success margin-right" onClick={this.chargeLesson.bind(this, props.match.params.studentId)} data-dismiss="modal">Đóng phí</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    async removePayment() {
        const { state } = this;
        return await apiRequestPost(`${DOMAIN_MAIN}/students/charge/remove`, JSON.stringify({ studentId: this.props.match.params.studentId, round: this.round })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getPaymentList(this.props.match.params.studentId);
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    renderModalRemovePayment() {
        return (
            <div className="modal" id="modalRemovePayment">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removePayment.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    latePayment = (index) => {
        const { state } = this;
        if (state.paymentList.classInfo) {
            const findIndex = state.listSchedule.findIndex(sh => !sh.startDate)
            if (index >= findIndex) return false
            const pay = state.paymentList.classInfo.payment.filter(p => p.round === index)
            if (!pay.length) return true;
            else {
                const totalCharge = pay[0].totalCharge;
                const sum = pay.reduce((accumulator, object) => {
                    return accumulator + object.charge;
                }, 0);
                if (sum < totalCharge) return true;
                else return false
            }

        }
        return true;
    }

    render() {
        const { state, props } = this;
        console.log(state.paymentList)
        return (
            <div className="dashboard-wrapper">
                <NotificationContainer />
                {this.renderModalDetailCharge()}
                {this.renderModalCharge()}
                {this.renderModalRemovePayment()}
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item"><a href="/admin/hoc-phi/all/1" className="breadcrumb-link">Quản lý học phí</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Chi tiết</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Chi tiết thanh toán học phí</h5>
                                <div className="card-body border-top">
                                    <div className="form-group">
                                        <label htmlFor="input-select">Tên học sinh</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.paymentList.studentName ? state.paymentList.studentName : ""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Tên lớp</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.paymentList.classInfo ? state.paymentList.classInfo.classId.description : ""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Loại lớp học</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.paymentList.classInfo ? state.paymentList.classInfo.classId.isAdvanced ? "Lớp nâng cao" : "Lớp thường" : ""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Số điện thoại</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.paymentList.phoneNumber} />
                                    </div>
                                    <div className="row margin-top">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Giai đoạn</th>
                                                                    <th>Bắt đầu</th>
                                                                    <th>Kết thúc</th>
                                                                    <th>Chức năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.listSchedule.map((ls, index) => {
                                                                    const isLate = this.latePayment(index, ls.startDate);
                                                                    return (
                                                                        <tr key={index} style={{ background: isLate ? 'red' : '', color: isLate ? 'white' : '' }}>
                                                                            <td>Lần {index + 1}</td>
                                                                            <td>{ls.startDate ? moment(ls.startDate).format("DD-MM-YYYY") : 'Chưa xác định'}</td>
                                                                            <td>{ls.endDate ? moment(ls.endDate).format("DD-MM-YYYY") : 'Chưa xác định'}</td>
                                                                            {!state.paymentList.classInfo.classId.isAdvanced ? <td>{
                                                                                !!ls.payment.length &&

                                                                                <button type="button" className="btn btn-danger margin-right" data-toggle="modal" data-target="#modalRemovePayment" onClick={() => { this.round = index }}>Xóa phí đóng</button>
                                                                            }
                                                                                <button type="button" className="btn btn-success margin-right"
                                                                                    data-toggle="modal" data-target="#modalCharge"
                                                                                    onClick={() => {
                                                                                        const payRound = state.paymentList.classInfo.payment.find(p => p.round === index);
                                                                                        let totalCharge = 0
                                                                                        if (payRound) {
                                                                                            totalCharge = payRound.totalCharge
                                                                                        }
                                                                                        this.refs['moneyCharge'].value = state.paymentList.classInfo.classId.price;
                                                                                        if (totalCharge) {
                                                                                            this.refs['totalCharge'].disabled = true;
                                                                                        } else {
                                                                                            this.refs['totalCharge'].disabled = false;
                                                                                        }
                                                                                        this.refs['totalCharge'].value = totalCharge || state.paymentList.classInfo.classId.price;
                                                                                        this.refs['note'].value = ''
                                                                                        this.setState({
                                                                                            chargeInfo: {
                                                                                                round: index,
                                                                                                startDate: moment(ls.startDate).format("DD-MM-YYYY"),
                                                                                                endDate: moment(ls.endDate).format("DD-MM-YYYY")
                                                                                            }
                                                                                        });
                                                                                    }}>Đóng phí</button>
                                                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalDetailCharge"
                                                                                    onClick={() => { this.setState({ chooseDetail: ls }) }}>Chi tiết</button>
                                                                            </td> :
                                                                                <td>
                                                                                    {!!ls.payment.length &&
                                                                                        <button type="button" className="btn btn-danger margin-right" data-toggle="modal" data-target="#modalRemovePayment" onClick={() => { this.round = index }}>Xóa phí đóng</button>
                                                                                    }
                                                                                    <button type="button" className="btn btn-success margin-right"
                                                                                        data-toggle="modal" data-target="#modalCharge"
                                                                                        onClick={() => {
                                                                                            const payRound = state.paymentList.classInfo.payment.find(p => p.round === index);
                                                                                            let totalCharge = 0
                                                                                            if (payRound) {
                                                                                                totalCharge = payRound.totalCharge
                                                                                            }
                                                                                            if (totalCharge) {
                                                                                                this.refs['totalCharge'].disabled = true;
                                                                                            } else {
                                                                                                this.refs['totalCharge'].disabled = false;
                                                                                            }
                                                                                            this.refs['moneyCharge'].value = state.paymentList.classInfo.classId.price;
                                                                                            this.refs['totalCharge'].value = totalCharge || state.paymentList.classInfo.classId.price;
                                                                                            this.refs['note'].value = ''
                                                                                            this.setState({
                                                                                                chargeInfo: {
                                                                                                    round: index,
                                                                                                    startDate: moment(ls.rollCall[0]).format("DD-MM-YYYY"),
                                                                                                    endDate: moment(ls.rollCall[ls.rollCall.length - 1]).format("DD-MM-YYYY")
                                                                                                }
                                                                                            });
                                                                                        }}>Đóng phí</button>
                                                                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalDetailCharge"
                                                                                        onClick={() => { this.setState({ chooseDetail: ls }) }}>Chi tiết</button>
                                                                                </td>}
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}