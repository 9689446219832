import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import moment from 'moment';
import _ from 'lodash';
import DatePicker from "react-datepicker";

export default class DetailPaymentSupplies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentList: [],
            dateCharge: new Date()
        }
        this.id = ''
    }

    handleChangeDateCharge = date => {
        this.setState({
            dateCharge: date
        });
    };

    getPaymentList(studentId) {
        let urlRequest = `${DOMAIN_MAIN}/students/payment/detail/${studentId}`;
        apiRequestGetAuthen(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                const payment = result.classInfo.payment.filter(r => { return !r.isLesson });
                result.classInfo.payment = payment;
                this.setState({ paymentList: result });
            }
        });
    }

    chargeSupply(studentId) {
        const { state } = this;
        const body = {
            studentId,
            charge: this.refs['moneyCharge'].value,
            desciption: this.refs['description'].value,
            date: state.dateCharge,
        }
        let urlRequest = `${DOMAIN_MAIN}/students/charge/supplies`;
        apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Không thể thanh toán", 'Lỗi', 2000);
            else {
                this.getPaymentList(this.props.match.params.studentId);
                NotificationManager.success("Thanh toán thành công", 'Thành công', 2000);
            }
        });
    }

    async removePayment() {
        const { state } = this;
        return await apiRequestPost(`${DOMAIN_MAIN}/students/charge/remove`, JSON.stringify({ studentId: this.props.match.params.studentId, id: this.id })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getPaymentList(this.props.match.params.studentId);
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    renderModalCharge() {
        const { state, props } = this;
        return (
            <div className="modal" id="modalCharge">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thanh toán học phí</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="input-select">Mô tả</label>
                                <input id="inputText3" type="text" className="form-control" ref="description" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="input-select">Số tiền đóng</label>
                                <input id="inputText3" type="number" className="form-control" ref="moneyCharge" />
                            </div>
                            <div className="container-datepicker">
                                <label htmlFor="input-select">Ngày thu tiền</label>
                                <DatePicker
                                    selected={state.dateCharge}
                                    onChange={this.handleChangeDateCharge}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success margin-right" onClick={this.chargeSupply.bind(this, props.match.params.studentId)} data-dismiss="modal">Đóng phí</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderModalRemovePayment() {
        return (
            <div className="modal" id="modalRemovePayment">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removePayment.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getPaymentList(this.props.match.params.studentId);
    }

    render() {
        const { state } = this;
        return (
            <div className="dashboard-wrapper">
                {this.renderModalCharge()}
                {this.renderModalRemovePayment()}
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item"><a href="/admin/hoc-phi/all/1" className="breadcrumb-link">Quản lý học phí</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Chi tiết</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Chi tiết phụ thu khác</h5>
                                <div className="card-body border-top">
                                    <div className="form-group">
                                        <label htmlFor="input-select">Tên học sinh</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.paymentList.studentName ? state.paymentList.studentName : ""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Tên lớp</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.paymentList.classInfo ? state.paymentList.classInfo.classId.description : ""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Loại lớp học</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.paymentList.classInfo ? state.paymentList.classInfo.classId.isAdvanced ? "Lớp nâng cao" : "Lớp thường" : ""} />
                                    </div>
                                    <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalCharge">Thêm mới</button></div>
                                    <div className="row margin-top">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Ngày đóng</th>
                                                                    <th>Tên khoản</th>
                                                                    <th>Số tiền</th>
                                                                    <th>Chức năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.paymentList.classInfo && state.paymentList.classInfo.payment.map((p, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                {moment(p.date).format("DD/MM/YYYY")}
                                                                            </td>
                                                                            <td>
                                                                                {p.desciption}
                                                                            </td>
                                                                            <td>
                                                                                {p.charge.toLocaleString()}
                                                                            </td>
                                                                            <td>                                                                                
                                                                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemovePayment" onClick={() => {
                                                                                    this.id = p._id;
                                                                                }}>Xóa</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}