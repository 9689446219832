import React, { Component } from "react";
import { DOMAIN, DOMAIN_MAIN } from "../../../../constants/requestUrl";
import { apiRequestFilePost, apiRequestGet, apiRequestPost } from "../../../../utils/requestHelper";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PaginationBt4 from "../../../Shared/PaginationBt4";

export default class Interview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interviews: [],
      totalPage: 0,
      page: 1,
      filePath: null,
      file: null,
      type: 0,
      questions: []
    }
    this.id = null
  }
  getInterviewList(page) {
    apiRequestGet(`${DOMAIN_MAIN}/interviews?${new URLSearchParams({ page })}`).then(result => {
      if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
      else this.setState({ interviews: result.interviews, totalPage: result.total })
    });
  }
  saveInterview() {
    const { state } = this;
    let body = {
      type: this.state.type,
      name: this.refs['name'].value,
      title: this.refs['title'].value,
      stadium: this.refs['stadium'].value,
      date: this.refs['date'].value,
      questions: JSON.stringify(this.state.questions),
    }
    if (this.id) {
      let urlRequest = "";
      body.id = this.id;
      if (state.file) {
        urlRequest = `${DOMAIN_MAIN}/interviews/edit`;
        apiRequestFilePost(urlRequest, state.file, body).then(result => {
          if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
          else {
            NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
            this.getInterviewList(state.page);
            this.refs['name'].value = "";
            this.refs['title'].value = "";
            this.refs['stadium'].value = "";
            this.refs['date'].value = "";
            this.setState({
              file: null,
              filePath: null,
              type: 0,
              questions: []
            })
          }
        });
      } else {
        body.questions = state.questions
        urlRequest = `${DOMAIN_MAIN}/interviews/edit/no-file`;
        apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
          if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
          else {
            NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
            this.getInterviewList(state.page);
            this.refs['name'].value = "";
            this.refs['title'].value = "";
            this.refs['stadium'].value = "";
            this.refs['date'].value = "";
            this.setState({
              file: null,
              filePath: null,
              type: 0,
              questions: []
            })
          }
        });
      }
    }
    else {
      if (state.file) {
        apiRequestFilePost(`${DOMAIN_MAIN}/interviews/create`, state.file, body).then(result => {
          if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
          else {
            NotificationManager.success("Lưu thành công", 'Thành công', 2000);
            this.getInterviewList(state.page);
            this.refs['name'].value = "";
            this.refs['title'].value = "";
            this.refs['stadium'].value = "";
            this.refs['date'].value = "";
            this.setState({
              file: null,
              filePath: null,
              type: 0,
              questions: []
            })
          }
        });
      } else {
        NotificationManager.error("Thêm hình ảnh", 'Lỗi', 2000);
      }
    }
  }
  renderModal() {
    const { state } = this;
    return (
      <div className="modal" id="modalContact">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Thêm mới</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="inputText3" className="col-form-label">Tựa đề</label>
                <input ref="title" id="inputText3" type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Tên</label>
                <input ref="name" id="inputText3" type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Cơ sở</label>
                <input ref="stadium" id="inputText3" type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Thời gian</label>
                <input ref="date" id="inputText3" type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Phụ huynh hay học sinh</label>
                <select className="form-control" id="input-select" onChange={(e) => { this.setState({ type: e.target.value }) }} value={state.type}>
                  <option value='0'>Phụ huynh</option>
                  <option value='1'>Học sinh</option>
                </select>
              </div>
              <img ref="imgEdit" src={state.filePath} className="img-thumbnail custom-thumbnail"></img>
              <div className="upload-btn-wrapper">
                <button className="btn btn-upload">Thêm hình ảnh</button>
                <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
              </div>
              <div className="form-group">
                <div>Câu hỏi và trả lời</div>
                <a href="#" onClick={() => {
                  let { questions } = state;
                  questions.push({ question: "", answer: "" })
                  this.setState({ questions });
                }}>Thêm câu hỏi và câu trả lời</a>
                {state.questions.map((q, i) => {
                  return (
                    <div className="form-group">
                      <div style={{ display: 'flex' }}>
                        <input id="inputText3" placeholder="Nhập câu hỏi" type="text" className="form-control" value={q.question} onChange={(e) => {
                          let { questions } = state;
                          questions[i].question = e.target.value;
                          this.setState({ questions: [...questions] })
                        }} />
                        <button type="button" className="btn btn-danger" onClick={() => {
                          let { questions } = state;
                          questions.splice(i, 1);
                          this.setState({ questions: [...questions] })
                        }}>Xóa</button>
                      </div>
                      <textarea id="inputText3" placeholder="Nhập câu trả lời" className="form-control" value={q.answer} onChange={(e) => {
                        let { questions } = state;
                        questions[i].answer = e.target.value;
                        this.setState({ questions: [...questions] })
                      }} />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={() => { this.saveInterview() }}>Lưu</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }
  handlePageChange(pageNumber) {
    this.props.history.push(`/admin/y-kien/${pageNumber}`)
    this.getInterviewList(pageNumber);
    this.setState({
      page: pageNumber
    })
  }
  async onChangeFile(e) {
    let file = e.target.files[0];
    if (file) {
      if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = await (e => {
          let filePath = e.target.result;
          this.setState({ filePath, file })
        });
      } else {
        NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
      }
    }
  }
  remove() {
    apiRequestPost(`${DOMAIN_MAIN}/interviews/remove`, JSON.stringify({ id: this.id, image: this.image })).then(result => {
      if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
      else {
        this.getInterviewList(this.state.page);
        NotificationManager.success("Xóa thành công", 'Thành công', 2000);
      }
    });
  }
  renderModalRemove() {
    return (
      <div className="modal" id="modalRemove">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Xác nhận xóa</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              Bạn có chắc chắn muốn xóa?
                    </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.remove.bind(this)} data-dismiss="modal">Đồng ý</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    const { props } = this;
    if (!props.match.params.page) {
      this.getInterviewList(1);
    } else {
      this.getInterviewList(props.match.params.page);
      this.setState({
        page: Number(props.match.params.page)
      })
    }
  }
  render() {
    const { state } = this;
    return (
      <div className="dashboard-wrapper">
        <NotificationContainer />
        {this.renderModal()}
        {this.renderModalRemove()}
        <div className="container-fluid  dashboard-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="page-header" id="top">
                <div className="page-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                      <li className="breadcrumb-item active" aria-current="page">ý kiến</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card">
                <h5 className="card-header">Quản lý ý kiến</h5>
                <div className="card-body border-top">
                  <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalContact" onClick={() => { this.id = "" }}>Thêm mới</button></div>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Tựa Đề</th>
                                  <th>Tên</th>
                                  <th>Cơ sở</th>
                                  <th>Thời gian học</th>
                                  <th>Hình Ảnh</th>
                                  <th>Phỏng vấn</th>
                                  <th>Chức Năng</th>
                                </tr>
                              </thead>
                              <tbody>

                                {state.interviews.map(interview => {
                                  return (
                                    <tr>
                                      <td>{interview.title}</td>
                                      <td>{interview.name}</td>
                                      <td>{interview.stadium}</td>
                                      <td>{interview.date}</td>
                                      <td><img className="img-table" src={`${DOMAIN}${interview.image}`}></img></td>
                                      <td>{interview.type === 0 ? "Phụ huynh" : "Học sinh"}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalContact" onClick={() => {
                                          this.refs['title'].value = interview.title;
                                          this.refs['name'].value = interview.name;
                                          this.refs['stadium'].value = interview.stadium;
                                          this.refs['date'].value = interview.date;
                                          this.refs['imgEdit'].src = `${DOMAIN}${interview.image}`;
                                          this.id = interview._id;
                                          this.image = interview.image;
                                          this.setState({ type: interview.type, questions: interview.questions })
                                        }}>Chỉnh Sửa</button>
                                        <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemove" onClick={() => {
                                          this.image = interview.image;
                                          this.id = interview._id;
                                        }}>Xóa</button>
                                      </td>
                                    </tr>)
                                })}

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                      <div className="pagination-highlights">
                        {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                          currentPage={state.page}
                          itemsCountPerPage={10}
                          handlePageChange={this.handlePageChange.bind(this)}
                        >
                        </PaginationBt4>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}