import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost } from '../../../../utils/requestHelper';
import PaginationBt4 from '../../../Shared/PaginationBt4';
import moment from 'moment';
import './RollCall.css';
import DatePicker from "react-datepicker";
import queryString from 'query-string';

export default class ReportRollcall extends Component {
    constructor(props) {
        super(props);
        const queryParam = queryString.parse(this.props.location.search)
        console.log(queryParam)
        this.state = {
            classList: [],
            studentList: [],
            stadiumInfo: '',
            stadiumModalInfo: '',
            totalPage: 0,
            currentPage: this.props.match.params.page ? Number(this.props.match.params.page) : 1,
            name: queryParam.name || '',
            rollCallDate: queryParam.date ? new Date(queryParam.date) : new Date(),
            typeRollCall: queryParam.typeRollCall || "0"
        }
        this.id = '';
    }

    getClassList() {
        let urlRequest = `${DOMAIN_MAIN}/classes/info`;
        apiRequestGet(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                this.setState({ classList: result });
            }
        });
    }

    getStadiumInfo(classId, isModal) {
        if (classId !== 'all') {
            let urlRequest = `${DOMAIN_MAIN}/students/stadium/info/${classId}`;
            apiRequestGet(urlRequest).then(result => {
                if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
                else isModal ? this.setState({ stadiumModalInfo: result.address }) : this.setState({ stadiumInfo: result.address })
            });
        } else {
            isModal ? this.setState({ stadiumModalInfo: '' }) : this.setState({ stadiumInfo: '' });
        }
    }

    getStudentsList(classId, pageNumber) {
        const { props } = this;
        const page = pageNumber ? pageNumber : (props.match.params.page ? props.match.params.page : 1);

        let urlRequest = `${DOMAIN_MAIN}/students/page/${page}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        if (classId && classId !== 'all') {
            urlRequest = `${DOMAIN_MAIN}/students/class/${classId}/page/${page}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        }
        else if (props.match.params.classId && classId !== 'all') {
            urlRequest = `${DOMAIN_MAIN}/students/class/${props.match.params.classId}/page/${page}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        }
        apiRequestGet(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ studentList: result.studentsList, totalPage: result.total })
        });
    }

    onChangeSelectStadium(e) {
        const { state } = this;
        this.setState({
            totalPage: 0
        })
        let checkClass = state.classList.find(cl => { return e.target.value === cl._id });
        if (this.props.match.params.page) {
            window.location.href = `/admin/bao-cao-diem-danh/${e.target.value}/${this.props.match.params.page}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        } else {
            window.location.href = `/admin/bao-cao-diem-danh/${e.target.value}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        }
    }

    onChangeTypeRollCall(e) {
        this.setState({
            typeRollCall: e.target.value
        })
        if (this.props.match.params.page) {
            window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}/${this.props.match.params.page}?${new URLSearchParams({ name: this.state.name, typeRollCall: e.target.value, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        } else {
            window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}?${new URLSearchParams({ name: this.state.name, typeRollCall: e.target.value, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        }
    }

    handlePageChange(pageNumber) {
        window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}/${pageNumber}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
        this.getStudentsList(this.props.match.params.classId, pageNumber);
        this.setState({
            currentPage: pageNumber
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.classId !== nextProps.match.params.classId) {
            this.getStadiumInfo(nextProps.match.params.classId);
            this.getStudentsList(nextProps.match.params.classId);
        }
    }

    componentDidMount() {
        this.getClassList();
        this.getStudentsList(this.props.match.params.classId);
        this.getStadiumInfo(this.props.match.params.classId);
    }

    rollCallStudent(id) {
        return apiRequestPost(`${DOMAIN_MAIN}/students/rollcall`, JSON.stringify({ studentId: id })).then(result => {
            if (result && result.error) NotificationManager.error("Điểm danh không thành công", 'Lỗi', 2000);
            else {
                this.getStudentsList(this.props.match.params.classId, this.props.match.params.page);
            }
        });
    }

    removeRollCallStudent(id) {
        return apiRequestPost(`${DOMAIN_MAIN}/students/rollcall/remove`, JSON.stringify({ studentId: id })).then(result => {
            if (result && result.error) NotificationManager.error("Hủy điểm danh không thành công", 'Lỗi', 2000);
            else {
                this.getStudentsList(this.props.match.params.classId, this.props.match.params.page);
                NotificationManager.success("Hủy điểm danh thành công", 'Thành công', 2000);
            }
        });
    }

    handleChangeRollCallDate = date => {
        if (this.props.match.params.page) {
            window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}/${this.props.match.params.page}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(date).format("YYYY-MM-DD") })}`;
        } else {
            window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(date).format("YYYY-MM-DD") })}`;
        }
    };

    render() {
        const { state, props } = this;
        const { typeRollCall } = state;
        const id = props.match.params.classId;
        return (
            <div className="dashboard-wrapper">
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Điểm danh</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Báo cáo điểm danh học sinh</h5>
                                <div className="card-body border-top">
                                    <div className="form-group">
                                        <label htmlFor="input-select">Danh sách lớp học</label>
                                        <select className="form-control" id="input-select" onChange={this.onChangeSelectStadium.bind(this)} value={id}>
                                            <option value='all'>Tất cả lớp học</option>
                                            {
                                                state.classList.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value._id}> {value.description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="input-select">Tìm kiếm tên</label>
                                        <div style={{ display: 'flex' }}>
                                            <input id="inputText3" type="text" className="form-control" ref="name" value={state.name} onChange={(e) => {
                                                this.setState({ name: e.target.value })
                                            }} onKeyDown={(event) => {
                                                if (event.keyCode === 13) {
                                                    console.log("aaaaa")
                                                    if (this.props.match.params.page) {
                                                        window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}/${this.props.match.params.page}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
                                                    } else {
                                                        window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
                                                    }
                                                }
                                            }
                                            } />
                                            <button className="btn btn-upload" onClick={() => {
                                                if (this.props.match.params.page) {
                                                    window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}/${this.props.match.params.page}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
                                                } else {
                                                    window.location.href = `/admin/bao-cao-diem-danh/${this.props.match.params.classId}?${new URLSearchParams({ name: this.state.name, typeRollCall: this.state.typeRollCall, date: moment(this.state.rollCallDate).format("YYYY-MM-DD") })}`;
                                                }
                                                //this.getStudentsList(this.props.match.params.classId, this.props.match.params.page);
                                            }}>Tìm kiếm</button>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div className="form-group" style={{ width: "50%", marginRight: "10px" }}>
                                            <label htmlFor="input-select">Trạng thái</label>
                                            <select className="form-control" id="input-select" onChange={this.onChangeTypeRollCall.bind(this)} value={typeRollCall}>
                                                <option value="0">Đã điểm danh</option>
                                                <option value="1">Chưa điểm danh</option>
                                            </select>
                                        </div>
                                        <div className="form-group" style={{ width: "50%", marginLeft: "10px" }}>
                                            <label htmlFor="input-select">Ngày điểm danh</label>
                                            <div>
                                                <DatePicker
                                                    selected={state.rollCallDate}
                                                    onChange={this.handleChangeRollCallDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <h2 style={{ marginBottom: "16px" }}>Tổng số học sinh: {this.state.totalPage}</h2>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>STT</th>
                                                                    <th>Tên</th>
                                                                    <th>Ghi Chú</th>
                                                                    <th>Tuổi</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.studentList.map((st, index) => {
                                                                    const currentTime = moment(Date.now()).format('YYYY-MM-DD');
                                                                    const called = st.classInfo.rollCall.find(rc => { return moment(rc).format('YYYY-MM-DD') === currentTime });
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{(state.currentPage - 1) * 10 + index + 1}</td>
                                                                            <td>{st.studentName}</td>
                                                                            <td>{st.parentName}</td>
                                                                            <td>{st.age}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalDetailRollCall" onClick={() => {
                                                                                    window.location.href = `/admin/chi-tiet/diem-danh/${st._id}/1`;
                                                                                }}>Chi tiết</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <div className="pagination-highlights">
                                            {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                                                currentPage={state.currentPage}
                                                itemsCountPerPage={10}
                                                handlePageChange={this.handlePageChange.bind(this)}
                                            >
                                            </PaginationBt4>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}