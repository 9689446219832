import React, { Component } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import routes from './routeBody'
export default class Body extends Component {
    render() {
        return (
            <>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} />
                                )} />
                        ) : (null);
                    })}
                    <Redirect to="/trang-chu" />
                </Switch>
                <a className="contact-phone" href="tel:0844461616">
                    <img src={require("../../../resources/images/phone.png")}></img>
                    <div className="title">Liên hệ ngay</div>
                </a>
            </>
        )
    }
}