import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store/rootStore'
import routes from './routes/routeRoot'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import "react-datepicker/dist/react-datepicker.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                  <route.component {...props} />
                )} />
            ) : (null);
          })}
          <Redirect from="/" to="/trang-chu" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
