import React, { Component } from 'react';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { apiRequestGet, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import "./Contact.css"
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactList: [],
            stadiumList: [],
            collapse: [false, false, false],
            banner: {}
        }
    }
    getStadiumsList(page) {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/stadiums`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ stadiumList: result })
        });
    }

    getBanner() {
        apiRequestGet(`${DOMAIN_MAIN}/banners/3`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ banner: result })
        });
    }

    getContactList() {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/contact`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ contactList: result })
        });
    }

    componentDidMount() {
        this.getContactList();
        this.getStadiumsList();
        this.getBanner();
    }

    render() {
        const { state } = this;
        let { collapse } = state;
        return (
            <>
                <div className="top-banner-register">
                    <img className="img-top1" src={`${DOMAIN}${state.banner.image}`}></img>
                </div>
                <div className="py-5 background-img-header contact-page">
                    <div className="row top-banner">
                        <a className="banner-content" href="/dang-ky">
                            <img className="icon" src={require("../../../../resources/images/banner-icon.png")}></img>
                            <div>ĐĂNG KÝ NGAY ĐỂ ĐƯỢC TRẢI NGHIỆM <b>MIỄN PHÍ</b></div>
                        </a>
                        <img src={require("../../../../resources/images/banner.jpg")}></img>
                    </div>
                    <div className="top-title">
                        <img src={require("../../../../resources/images/location.png")}></img>THÔNG TIN CƠ SỞ / HỌC PHÍ
                    </div>
                    <div className="google-map-nani">
                        <LoadScript
                            id="script-loader"
                            googleMapsApiKey="AIzaSyBVbm4WyEDHV39dJu1J7KUhv_qaumte6J8"
                        >
                            <GoogleMap
                                zoom={12}
                                center={{ lat: 16.026912741986386, lng: 108.24151579999517 }}
                            >
                                <Marker position={{ lat: 16.026912741986386, lng: 108.24151579999517 }} />
                                <Marker position={{ lat: 16.0786842001115, lng: 108.21882174098435 }} />
                                <Marker position={{ lat: 16.047387725953683, lng: 108.21004943953311 }} />
                                <Marker position={{ lat: 16.056133361886214, lng: 108.23433416651778 }} />
                                <Marker position={{ lat: 16.07661122627161, lng: 108.23602949324092 }} />
                                <Marker position={{ lat: 16.027077720081806, lng: 108.24163381439935 }} />
                                <Marker position={{ lat: 16.055026527634144, lng: 108.2347024278921 }} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    <div className="location-contact">
                        <div className="item">
                            <div className="contact-title">Các cơ sở của NanikidsFutsal</div>
                            <div className="content">
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 1: Sân bóng đá Thép Việt, đường Nghiêm Xuân Yêm, Q. Ngũ Hành Sơn, TPĐN
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/S%C3%A2n+B%C3%B3ng+%C4%90%C3%A1+Th%C3%A9p+Vi%E1%BB%87t/@16.026789,108.2415158,15z/data=!4m2!3m1!1s0x0:0xb1a50d13937a331f?sa=X&ved=2ahUKEwj0xPm7kNfvAhWzIaYKHZZkAYoQ_BIwCnoECBgQBQ">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Sáng Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 6h30 đến 8h</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Chiều Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 16h30 đến 17h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 2: Sân bóng Trưng Vương, 560 đường Trưng Nữ Vương, Q. Hải Châu, TPĐN
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/Trung+Vuong+football+field/@16.0471609,108.2100709,15z/data=!4m2!3m1!1s0x0:0xe5e9b113bc37fd33?sa=X&ved=2ahUKEwi7n5LVkNfvAhU8w4sBHeimBPAQ_BIwC3oECBcQBQ">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Tối Thứ 4 {`&`} Thứ 5</div>
                                                <div className="schedule-time">Từ 19h30 đến 21h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 3: Sân bóng Trưng Vương, 560 đường Trưng Nữ Vương, Q. Hải Châu, TPĐN
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/Trung+Vuong+football+field/@16.0471609,108.2100709,15z/data=!4m2!3m1!1s0x0:0xe5e9b113bc37fd33?sa=X&ved=2ahUKEwi7n5LVkNfvAhU8w4sBHeimBPAQ_BIwC3oECBcQBQ">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Sáng Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 6h30 đến 8h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Chiều Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 16h30 đến 17h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 4: Trung tâm văn hoá thể thao Quận Sơn Trà, số 01 đường Trần Quang Diệu
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/01+Tr%E1%BA%A7n+Quang+Di%E1%BB%87u,+An+H%E1%BA%A3i+T%C3%A2y,+S%C6%A1n+Tr%C3%A0,+%C4%90%C3%A0+N%E1%BA%B5ng+550000,+Vietnam/@16.0547894,108.2324279,17z/data=!3m1!4b1!4m5!3m4!1s0x314219d6f8d7643b:0xf4c5ddb7d6602124!8m2!3d16.0547894!4d108.2346166">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Sáng Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 6h30 đến 8h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 5: Bóng đá học đường Trường tiểu học, THCS FPT Đà Nẵng
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/Tr%C6%B0%E1%BB%9Dng+Ti%E1%BB%83u+h%E1%BB%8Dc+v%C3%A0+THCS+FPT+%C4%90%C3%A0+N%E1%BA%B5ng/@16.0765017,108.2362161,15z/data=!4m5!3m4!1s0x0:0x5e196f33de7e0b99!8m2!3d16.0765017!4d108.2362161">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Thứ 2, Thứ 3, Thứ 4 {`&`} Thứ 5</div>
                                                <div className="schedule-time">Từ 16h30 đến 17h30</div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 6: BÓNG ĐÁ HỌC ĐƯỜNG Learning Home, Sân bóng đá Thép Việt, đường Nghiêm Xuân Yêm, Q. Ngũ Hành Sơn, TPĐN
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/S%C3%A2n+B%C3%B3ng+%C4%90%C3%A1+Th%C3%A9p+Vi%E1%BB%87t/@16.026789,108.2415158,15z/data=!4m2!3m1!1s0x0:0xb1a50d13937a331f?sa=X&ved=2ahUKEwj0xPm7kNfvAhWzIaYKHZZkAYoQ_BIwCnoECBgQBQ">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Tối Thứ 5</div>
                                                <div className="schedule-time">Từ 18h30 đến 19h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 7: Trung tâm văn hoá thể thao Quận Sơn Trà, số 01 đường Trần Quang Diệu
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/01+Tr%E1%BA%A7n+Quang+Di%E1%BB%87u,+An+H%E1%BA%A3i+T%C3%A2y,+S%C6%A1n+Tr%C3%A0,+%C4%90%C3%A0+N%E1%BA%B5ng+550000,+Vietnam/@16.0547894,108.2324279,17z/data=!3m1!4b1!4m5!3m4!1s0x314219d6f8d7643b:0xf4c5ddb7d6602124!8m2!3d16.0547894!4d108.2346166">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Tối Thứ 2 {`&`} Thứ 6</div>
                                                <div className="schedule-time">Từ 19h30 đến 20h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 8: Lớp nâng cao Nani Kids Futsal, Nhà thi đấu QK5, số 07 Duy Tân, Q.Hải Châu
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/07+Duy+T%C3%A2n,+Ho%C3%A0+C%C6%B0%E1%BB%9Dng+B%E1%BA%AFc,+H%E1%BA%A3i+Ch%C3%A2u,+%C4%90%C3%A0+N%E1%BA%B5ng+550000,+Vietnam/@16.0473038,108.2138085,17z/data=!3m1!4b1!4m5!3m4!1s0x314219c0cc42fab7:0xdc9af192a87e5f04!8m2!3d16.0473038!4d108.2159972">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Tối Thứ 3 {`&`} Thứ 5</div>
                                                <div className="schedule-time">Từ 19h30 đến 21h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 9: Sân bóng Ô Bầu, A2-11 Võ Chí Công, Q.Cẩm Lệ
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/search/S%C3%A2n+b%C3%B3ng+%C3%94+B%E1%BA%A7u,+A2-11+V%C3%B5+Ch%C3%AD+C%C3%B4ng/@16.0076556,108.2180562,17z/data=!3m1!4b1">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Sáng Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 6h30 đến 7h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Chiều Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 17h30 đến 18h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 10: Sân bóng Nguyễn Chánh, 86 Nguyễn Chánh, Hoà Khánh, Q.Liên Chiểu
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/S%C3%A2n+B%C3%B3ng+%C4%90%C3%A1+Nguy%E1%BB%85n+Ch%C3%A1nh/@16.0842863,108.150894,15z/data=!4m2!3m1!1s0x0:0x19f4fd6f41ee6?sa=X&ved=2ahUKEwjkopyJyInwAhWQHXAKHQh5DRcQ_BIwEHoECBoQAw">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Sáng Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 6h30 đến 8h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 11: Sân bóng Thanh Thanh, 79 Ngô Văn Sở sau lưng trường Phan Phu Tiên, Hoà Khánh, Q.Liên Chiểu
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/S%C3%A2n+b%C3%B3ng+Thanh+Thanh/@16.0666448,108.1492439,18.08z/data=!4m5!3m4!1s0x0:0xe4aab088d70be14!8m2!3d16.066919!4d108.1490711">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Chiều Thứ 3 {`&`} Thứ 5</div>
                                                <div className="schedule-time">Từ 16h30 đến 17h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 12: Sân bóng Mường Thanh, góc 24/3 và Huỳnh Thúc Kháng, Tam Kỳ, Quảng Nam
                                        </span>
                                        <a target="_blank" href="https://www.google.com/search?tbs=lf:1,lf_ui:4&tbm=lcl&sxsrf=ALiCzsZJ4DKvpzMnonFu9AiaHXkZiKtgHA:1661874142588&q=S%C3%A2n+b%C3%B3ng+M%C6%B0%E1%BB%9Dng+Thanh+tam+k%E1%BB%B3&rflfq=1&num=10&rldimm=[object%20Object]&ved=2ahUKEwikrbjP8-75AhWOCt4KHRRCDHoQu9QIegQICBAK#rlfi=hd:;si:14091986251393210180;mv:[[15.564023980109148,108.49109453377912],[15.561093866862821,108.48510784325788],null,[15.562558928702513,108.4881011885185],18]">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Tối Thứ 4 {`&`} Thứ 5</div>
                                                <div className="schedule-time">Từ 19h00 đến 20h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Sáng Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 6h30 đến 8h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Chiều Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 16h00 đến 17h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 13: Sân bóng Chuyên Việt, K151 Âu Cơ, Hoà Khánh, Q.Liên Chiểu
                                        </span>
                                        <a target="_blank" href="https://www.google.com/search?q=S%C3%A2n+b%C3%B3ng+Chuy%C3%AAn+Vi%E1%BB%87t&tbm=lcl&sxsrf=ALiCzsZdQeKANBRjLZHffgr-kkJ6mLfNnA%3A1661874148290&ei=5C8OY52xEZ-A1e8PhN-ooAk&ved=0ahUKEwids5TS8-75AhUfQPUHHYQvCpQQ4dUDCAk&uact=5&oq=S%C3%A2n+b%C3%B3ng+Chuy%C3%AAn+Vi%E1%BB%87t&gs_lcp=Cg1nd3Mtd2l6LWxvY2FsEAMyBQgAEMsBMgUIABDLATIGCAAQHhAWMggIABAeEA8QFjIGCAAQHhAWMgYIABAeEBYyBggAEB4QFjIGCAAQHhAWMgYIABAeEBZQAFgAYOsBaABwAHgAgAFkiAFkkgEDMC4xmAEAoAECoAEBwAEB&sclient=gws-wiz-local#rlfi=hd:;si:1658093238522239791;mv:[[16.045282577319025,108.21455627263316],[16.044922622680968,108.21418172736684]]">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Chiều Thứ 7 {`&`} Chủ Nhật</div>
                                                <div className="schedule-time">Từ 16h15 đến 17h30</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 14: Sân bóng tại công ty FASHION GARMENTS, Tam Thăng, Tam Kỳ, Quảng Nam
                                        </span>
                                        <a target="_blank" href="https://www.google.com/search?q=c%C3%B4ng+ty+FASHION+GARMENTS&tbm=lcl&sxsrf=ALiCzsZoTuSkS_VcMFpJiRivA8KMBXjlFA%3A1661874355826&ei=szAOY6mLMoSO-AbZoIjwCg&ved=0ahUKEwjpsI-19O75AhUEB94KHVkQAq4Q4dUDCAk&uact=5&oq=c%C3%B4ng+ty+FASHION+GARMENTS&gs_lcp=Cg1nd3Mtd2l6LWxvY2FsEAMyBAgAEBMyBAgAEBMyBAgAEBMyBAgAEBMyBAgAEBMyBwgAEMkDEBMyCAgAEB4QFhATMggIABAeEBYQEzIICAAQHhAWEBMyCAgAEB4QFhATOgQIIxAnOgUIABDLAToGCAAQHhAWOggIABAeEA8QFlDIAVjIAWD0BGgAcAB4AIABcogBywGSAQMxLjGYAQCgAQKgAQHAAQE&sclient=gws-wiz-local#rlfi=hd:;si:14531521148389973200;mv:[[15.622252194995768,108.47296702103324],[15.615773620131982,108.46099363999076],null,[15.619012933163033,108.466980330512],17]">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Chiều Thứ 7</div>
                                                <div className="schedule-time">Từ 17h30 đến 19h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="title">
                                        <span>
                                            Cơ sở 15: Lớp bóng dành cho người lớn, Sân bóng Trưng Vương, 560 đường Trưng Nữ Vương, Q. Hải Châu, TPĐN
                                        </span>
                                        <a target="_blank" href="https://www.google.com/maps/place/Trung+Vuong+football+field/@16.0471609,108.2100709,15z/data=!4m2!3m1!1s0x0:0xe5e9b113bc37fd33?sa=X&ved=2ahUKEwi7n5LVkNfvAhU8w4sBHeimBPAQ_BIwC3oECBcQBQ">Xem bản đồ</a>
                                    </div>
                                    <div className="schedule">
                                        <div>
                                            <div className="schedule-item">
                                                <div className="schedule-title">Tối Thứ 4</div>
                                                <div className="schedule-time">Từ 19h30 đến 21h00</div>
                                                <div className="schedule-time"></div>
                                                <a href="/dang-ky">Đăng ký ngay</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row top-banner">
                        <a className="banner-content" href="/dang-ky">
                            <img className="icon" src={require("../../../../resources/images/banner-icon.png")}></img>
                            <div>ĐĂNG KÝ NGAY ĐỂ ĐƯỢC TRẢI NGHIỆM <b>MIỄN PHÍ</b></div>
                        </a>
                        <img src={require("../../../../resources/images/banner.jpg")}></img>
                    </div>
                </div>
            </>
        )
    }
}