import React, { Component } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import routes from './routeBodyAdmin'

export default class Body extends Component {
    render() {
        return (
            <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (
                        <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => (
                                <route.component {...props}/>
                            )} />
                    ) : (null);
                })}
                <Redirect to="/admin/chinh-sua-giao-dien" />
            </Switch>
        )
    }
}