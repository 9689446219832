import ClientPage from "../components/Client/ClientPage";
import Admin from "../components/Admin/Admin";
import Login from "../components/Admin/Login";

const routes = [
    { path: '/admin', exact: false, name: 'Admin', component: Admin },
    { path: '/login', exact: false, name: 'Login', component: Login },
    { path: '/', exact: false, name: 'ClientPage', component: ClientPage },
];

export default routes;