import React, { Component } from 'react';
import './Home.css';
import OwlCarousel from 'react-owl-carousel';
import { apiRequestGet, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import moment from 'moment';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.responsiveInfo = {
            0: {
                items: 1,
                nav: true,
                loop: true
            },
            600: {
                items: 1,
                nav: true,
                loop: true
            },
            1000: {
                items: 3,
                nav: true,
                loop: true
            }
        }
        this.responsiveHLV = {
            0: {
                items: 1,
                nav: true,
                loop: true
            },
            1000: {
                items: 2,
                nav: true,
                loop: true
            }
        }
        this.state = {
            functionsList: [],
            newest: [],
            stadiumList: [],
            teachersList: [],
            contactList: [],
            investors: [],
            banners: [],
            methods: [],
            video: {},
            bannerList: [],
        }
    }

    getbannerList(page) {
        apiRequestGet(`${DOMAIN_MAIN}/banners`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ bannerList: result })
        });
    }

    getMethodList() {
        apiRequestGet(`${DOMAIN_MAIN}/methods`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ methods: result })
        });
    }

    getTopVideo() {
        apiRequestGet(`${DOMAIN_MAIN}/top-video`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ video: result })
        });
    }

    getContactList() {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/contact`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ contactList: result })
        });
    }

    getTeachersList() {
        apiRequestGet(`${DOMAIN_MAIN}/teachers`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ teachersList: result })
        });
    }

    getFunctionList() {
        apiRequestGet(`${DOMAIN_MAIN}/functions`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                if (result.length < 3) {
                    this.responsiveInfo = {
                        0: {
                            items: 1,
                            nav: true,
                            loop: true
                        },
                        600: {
                            items: result.length,
                            nav: true,
                            loop: true
                        }
                    }
                }
                this.setState({ functionsList: result.reverse() });
            }
        });
    }

    getNewest() {
        apiRequestGet(`${DOMAIN_MAIN}/newest`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                this.setState({ newest: result });
            }
        });
    }

    getStadiumsList() {
        apiRequestGet(`${DOMAIN_MAIN}/stadiums`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ stadiumList: result })
        });
    }

    getInvestorList() {
        apiRequestGet(`${DOMAIN_MAIN}/investors`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ investors: result })
        });
    }

    componentDidMount() {
        this.getFunctionList();
        this.getNewest();
        this.getStadiumsList();
        this.getTeachersList();
        this.getContactList();
        this.getInvestorList();
        this.getbannerList();
        this.getMethodList();
        this.getTopVideo();
    }

    render() {
        const { state } = this;
        const diamonds = state.investors.filter(i => { return i.type === 0 })
        const styleDiamond = diamonds.length === 1 ? { width: "70%" } : { width: "calc(50% - 40px)" }
        const platinums = state.investors.filter(i => { return i.type === 1 })
        const golds = state.investors.filter(i => { return i.type === 2 })
        const stylePlatinum = platinums.length === 1 ? { width: "33%" } : { width: "calc(33% - 40px)" }
        const styleGold = golds.length === 1 ? { width: "33%" } : { width: "calc(33% - 40px)" }

        return (
            <>
                <div>
                    {this.state.bannerList.length > 0 && <div className="header-slider">
                        <OwlCarousel className="owl-theme"
                            loop={true}
                            autoplay={true}
                            nav={true}
                            dots={false}
                            items={1}
                            animateIn='fadeIn' // add this
                            animateOut='fadeOut'
                        >
                            {this.state.bannerList.filter(banner => !banner.typeImage).map(image => {
                                return (
                                    <img className="header-img" src={`${DOMAIN}${image.image}`} alt=""></img>
                                )
                            })}
                        </OwlCarousel>
                    </div>}
                </div>
                <div className="site-section home-page">
                    <div className="container normal-container">
                        <NotificationContainer />
                        <div className="row top-banner">
                            <a className="banner-content" href="/dang-ky">
                                <img className="icon" src={require("../../../../resources/images/banner-icon.png")}></img>
                                <div>ĐĂNG KÝ NGAY ĐỂ ĐƯỢC TRẢI NGHIỆM <b>MIỄN PHÍ</b></div>
                            </a>
                            <img src={require("../../../../resources/images/banner.jpg")}></img>
                        </div>
                        <AudioPlayer
                            style={{ borderRadius: "1rem", marginTop: '30px', marginBottom: "30px" }}
                            autoPlay
                            src={require("../../../../resources/NaniKidsFutsal.mp3")}
                            onPlay={(e) => console.log("onPlay")}
                            showSkipControls={true}
                            showJumpControls={false}
                            header={`Đang phát giới thiệu về Nani Kids Futsal`}
                        // other props here
                        />
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">NaNi Kids Futsal đào tạo <b>kỹ năng chơi bóng</b> đồng thời giáo dục giúp trẻ có <b>kỹ năng sống</b> tốt hơn</h2>
                            </div>
                        </div>
                        <div className="row row-padding">
                            {this.state.methods.map((method, i) => (
                                <div key={i} className="col-lg-4 col-md-6" onClick={() => { window.location.href = "/phuong-phap" }}>
                                    <img src={`${DOMAIN}${method.image}`}></img>
                                    <div className="title">{method.title}</div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Các hoạt động của chúng tôi</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 block-13 nav-direction-white">
                                {state.functionsList.length > 0 && <OwlCarousel
                                    className="owl-theme"
                                    loop={true}
                                    autoplay={true}
                                    margin={10}
                                    nav={true}
                                    dots={false}
                                    responsive={this.responsiveInfo}
                                >
                                    {state.functionsList.map((fc, index) => {
                                        return (
                                            <div className="media-image item" key={index}>
                                                <div className="work-item">
                                                    <img src={`${DOMAIN}${fc.urlImage}`} alt="Image" className="img-fluid" />
                                                </div>
                                                <div className="media-image-body">
                                                    <h2>{fc.title}</h2>
                                                    <p>{fc.description}</p>
                                                    {/* <p><a href="#" className="btn btn-primary text-white px-4"><span className="caption">Learn More</span></a></p> */}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </OwlCarousel>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Kế hoạch phát triển của chúng tôi</h2>
                            </div>
                            <div className='col-md-12 text-center mb-4'>
                                <a style={{ fontSize: '24px', color: '#7bb641', fontWeight: 'bold' }} href={this.state.bannerList.find(banner => banner.typeImage == 9) ?
                                    this.state.bannerList.find(banner => banner.typeImage == 9).url : ""} rel="nofollow noopener" target="_blank">Xem chi tiết tại đường dẫn</a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Nhà tài trợ kim cương</h2>
                            </div>
                        </div>
                        <div className="investor-container">
                            {diamonds.map((investor, i) => {
                                return (
                                    <a href={investor.url} target="_blank">
                                        <img className="investor-image" src={`${DOMAIN}${investor.image}`} style={styleDiamond}></img>
                                    </a>
                                )
                            })}
                            <div className="col-md-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Nhà tài trợ bạch kim</h2>
                            </div>
                        </div>
                        <div className="investor-container">
                            {platinums.map((investor, i) => {
                                return (
                                    <a href={investor.url} target="_blank">
                                        <img className="investor-image" src={`${DOMAIN}${investor.image}`} style={stylePlatinum}></img>
                                    </a>
                                )
                            })}
                            <div className="col-md-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Nhà tài trợ vàng</h2>
                            </div>
                        </div>
                        <div className="investor-container">
                            {golds.map((investor, i) => {
                                return (
                                    <a href={investor.url} target="_blank">
                                        <img className="investor-image" src={`${DOMAIN}${investor.image}`} style={styleGold}></img>
                                    </a>
                                )
                            })}
                            <div className="col-md-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Các đơn vị liên kết giảng dạy bóng đá học đường</h2>
                            </div>
                        </div>
                        <div className="investor-container">
                            {state.investors.filter(i => { return i.type === 3 }).map((investor, i) => {
                                return (
                                    <a href={investor.url} target="_blank">
                                        <img className="investor-image" src={`${DOMAIN}${investor.image}`}></img>
                                    </a>
                                )
                            })}
                            <div className="col-md-4"></div>
                        </div>
                        <div className="row container-margin">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Video về NanikidsFusal</h2>
                            </div>
                        </div>
                        <div className="row">
                            <iframe className="iframe-video" src={this.state.video.url || ""} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                        </div>
                        <div className="row container-margin character-title">
                            <div className="col-md-6 image">
                                <img src={`${DOMAIN}${this.state.bannerList.find(banner => banner.typeImage == 1) ? this.state.bannerList.find(banner => banner.typeImage == 1).image : ""}`}></img>
                            </div>
                            <div className="col-md-6">
                                <div className="title">Lời nhắn từ Thầy Nguyễn Quốc Bình</div>
                                <div className="description">Chào mừng ba mẹ đã ghé thăm website của Nani Kids Futsal.</div>
                                <p className="content">Lớp học bóng đá NaNi Kids là lớp học không chỉ phát triển về bóng đá mà còn đào tạo kỹ năng sống cho trẻ trong môi trường quốc tế hoá , đó chính là mục tiêu  giáo dục chủ yếu của trung tâm bóng đá NaNi Kids Futsal chúng tôi.</p>
                                <p className="content">Ở Việt Nam hiện nay, giáo dục liên quan đến kiến thức, tri thức vẫn chưa đi kèm nhiều đến việc phát triển "kỹ năng mềm". Trẻ em hiện đại cần phải có khả năng giao tiếp xã hội, khả năng phán đoán và xử lý, giải quyết vấn đề, thể hiện bản lĩnh của mình ngay từ khi còn nhỏ</p>
                                <p className="content">Thời đại công nghiệp hoá, hiện đại hoá như ngày nay, để có thể thành công thì chắc chắn không thể thiếu bản lĩnh và tính kiên trì nỗ lực nêu trên. NaNi Kids Futsal luôn cố gắng phát triển, đào tạo những thủ lĩnh trẻ cống hiến cho sự phát triển chung của xã hội </p>
                                <p className="content">Cám ơn các phụ huynh đã đọc những chia sẻ này của tôi, rất mong có cơ hội đồng hành cùng quý phụ huynh để xây dựng một tương lai cho trẻ em tốt hơn</p>
                            </div>
                        </div>
                        <div className="row container-margin">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center text-color-green">Các cơ sở của Nani Kids Futsal</h2>
                            </div>
                        </div>
                        <div className="google-map-nani">
                            <LoadScript
                                id="script-loader"
                                googleMapsApiKey="AIzaSyBVbm4WyEDHV39dJu1J7KUhv_qaumte6J8"
                            >
                                <GoogleMap
                                    zoom={12}
                                    center={{ lat: 16.026912741986386, lng: 108.24151579999517 }}
                                >
                                    <Marker position={{ lat: 16.026912741986386, lng: 108.24151579999517 }} />
                                    <Marker position={{ lat: 16.0786842001115, lng: 108.21882174098435 }} />
                                    <Marker position={{ lat: 16.047387725953683, lng: 108.21004943953311 }} />
                                    <Marker position={{ lat: 16.056133361886214, lng: 108.23433416651778 }} />
                                    <Marker position={{ lat: 16.07661122627161, lng: 108.23602949324092 }} />
                                    <Marker position={{ lat: 16.027077720081806, lng: 108.24163381439935 }} />
                                    <Marker position={{ lat: 16.055026527634144, lng: 108.2347024278921 }} />
                                </GoogleMap>
                            </LoadScript>
                        </div>
                        <div className="row container-margin">
                            <div className="col-md-12">
                                <h2 className="site-section-heading text-center margin-top text-color-green">Tin tức</h2>
                            </div>
                        </div>
                        <div className="row mb-5">
                            {state.newest.map((nw, index) => {
                                return (
                                    <div className="col-md-6 col-lg-4 mb-5 mb-lg-0 container-news" key={index}>
                                        <div className="media-with-text mb-5">
                                            <div className="mb-4">
                                                <a href={`/tin-tuc/chi-tiet/${nw._id}`}>
                                                    <img src={`${DOMAIN}${nw.thumbnail}`} alt="" className="img-fluid" />
                                                </a>
                                            </div>
                                            <h2 className="h5 mb-2"><a href={`/tin-tuc/chi-tiet/${nw._id}`}>{nw.title}</a></h2>
                                            <span className="mb-3 d-block post-date">{moment(nw.created).format("DD/MM/YYYY HH:mm")}</span>
                                            <p>{nw.shortContent}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="site-section block-13 bg-light nav-direction-white section-focus">
                            <div className="container">
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <h2 className="site-section-heading text-center color-white">Đội ngũ huấn luyện viên</h2>
                                    </div>
                                </div>
                                {state.teachersList.length > 0 && <OwlCarousel
                                    className="owl-theme"
                                    loop={true}
                                    autoplay={true}
                                    margin={10}
                                    responsive={this.responsiveHLV}
                                    nav={true}
                                >
                                    {state.teachersList.map((tc, index) => {
                                        return (
                                            <div className="p-5" key={index}>
                                                <div className="d-flex block-testimony">
                                                    <div className="person mr-3">
                                                        <img src={`${DOMAIN}${tc.image}`} alt="Image" className="img-fluid rounded-circle icon-teacher" />
                                                    </div>
                                                    <div className="color-white">
                                                        <h2 className="h5 color-white">{tc.name}</h2>
                                                        <span>{tc.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </OwlCarousel>
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}