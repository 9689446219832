import Home from "./Home/Home";
import News from "./News/News";
import Register from "./Register/Register";
import Contact from "./Contact/Contact";
import NewDetail from "./News/NewDetail";
import Method from "./Method/Method";
import Interview from "./Interview/Interview";
import Gallery from "./Gallery/Gallery";

const routes = [
    { path: '/trang-chu', exact: true, name: 'Home', component: Home },
    { path: '/tin-tuc', exact: true, name: 'News', component: News },
    { path: '/tin-tuc/chi-tiet/:newId', exact: true, name: 'News', component: NewDetail },
    { path: '/tin-tuc/:page', exact: true, name: 'News', component: News },
    { path: '/y-kien', exact: true, name: 'Interview', component: Interview },
    { path: '/dang-ky', exact: true, name: 'Register', component: Register },
    { path: '/lien-he', exact: true, name: 'Contact', component: Contact },
    { path: '/phuong-phap', exact: true, name: 'Contact', component: Method },
    { path: '/thu-vien-anh', exact: true, name: 'Gallery', component: Gallery },
];

export default routes;
