import UIManager from "./UIManager/UIManager";
import News from "./News/News";
import CreateNew from "./News/CreateNew";
import EditNews from "./News/EditNews";
import Stadiums from "./Stadiums/Stadiums";
import Class from "./Class/Class";
import Students from "./Students/Students";
import Registers from "./Registers/Registers";
import RollCall from "./RollCall/RollCall";
import DetailRollCall from "./RollCall/DetailRollCall";
import Payment from "./Payment/Payment";
import DetailPayment from "./Payment/DetailPayment";
import Teachers from "./Teachers/Teachers";
import Users from "./Users/Users";
import Revenue from "./Revenue/Revenue";
import DetailPaymentSupplies from "./Payment/DetailPaymentSupplies";
import Investor from "./Investor/Investor";
import Interview from "./Interview/Interview";
import TopBanner from "./TopBanner/TopBanner";
import Galleries from "./Galleries/Galleries";
import ReportRollcall from "./ReportRollCall/ReportRollCall";

const routes = [
    { path: '/admin/chinh-sua-giao-dien', exact: true, name: 'UIManager', component: UIManager },
    { path: '/admin/tin-tuc', exact: true, name: 'News', component: News },
    { path: '/admin/tin-tuc/chinh-sua', exact: true, name: 'EditNews', component: EditNews },
    { path: '/admin/tin-tuc/chinh-sua/:id', exact: true, name: 'EditNews', component: EditNews },
    { path: '/admin/tin-tuc/them-moi', exact: true, name: 'CreateNew', component: CreateNew },
    { path: '/admin/tin-tuc/:page', exact: true, name: 'News', component: News },
    { path: '/admin/tai-tro', exact: true, name: 'Investor', component: Investor },
    { path: '/admin/y-kien', exact: true, name: 'Interview', component: Interview },
    { path: '/admin/y-kien/:page', exact: true, name: 'Interview', component: Interview },
    { path: '/admin/quan-ly-thu-vien', exact: true, name: 'Galleries', component: Galleries },
    { path: '/admin/san-bong', exact: true, name: 'Stadiums', component: Stadiums },
    { path: '/admin/huan-luyen-vien', exact: true, name: 'Teachers', component: Teachers },
    { path: '/admin/tai-khoan', exact: true, name: 'Users', component: Users },
    { path: '/admin/lop-hoc', exact: true, name: 'Class', component: Class },
    { path: '/admin/lop-hoc/:stadiumId', exact: true, name: 'Class', component: Class },
    { path: '/admin/lop-hoc/loai-lop/:classType', exact: true, name: 'Class', component: Class },
    { path: '/admin/lop-hoc/:stadiumId/loai-lop/:classType', exact: true, name: 'Class', component: Class },
    { path: '/admin/hoc-sinh/:classId/:page', exact: true, name: 'Students', component: Students },
    { path: '/admin/hoc-sinh/:classId', exact: true, name: 'Students', component: Students },
    { path: '/admin/diem-danh/:classId/:page', exact: true, name: 'RollCall', component: RollCall },
    { path: '/admin/diem-danh/:classId', exact: true, name: 'RollCall', component: RollCall },
    { path: '/admin/dang-ky-moi', exact: true, name: 'Registers', component: Registers },
    { path: '/admin/dang-ky-moi/:page', exact: true, name: 'Registers', component: Registers },
    { path: '/admin/chi-tiet/diem-danh/:studentId/:page', exact: true, name: 'DetailRollCall', component: DetailRollCall },
    { path: '/admin/chi-tiet/diem-danh/:studentId', exact: true, name: 'DetailRollCall', component: DetailRollCall },
    { path: '/admin/chi-tiet/diem-danh/:studentId/:page/:startDate/:endDate', exact: true, name: 'DetailRollCall', component: DetailRollCall },
    { path: '/admin/hoc-phi/:classId/:page', exact: true, name: 'Payment', component: Payment },
    { path: '/admin/hoc-phi/:classId', exact: true, name: 'Payment', component: Payment },
    { path: '/admin/chi-tiet/hoc-phi/:studentId/:page', exact: true, name: 'DetailPayment', component: DetailPayment },
    { path: '/admin/chi-tiet/hoc-phi/:studentId', exact: true, name: 'DetailPayment', component: DetailPayment },
    { path: '/admin/chi-tiet/phu-thu/:studentId/:page', exact: true, name: 'DetailPaymentSupplies', component: DetailPaymentSupplies },
    { path: '/admin/chi-tiet/phu-thu/:studentId', exact: true, name: 'DetailPaymentSupplies', component: DetailPaymentSupplies },
    { path: '/admin/doanh-thu/:classId/:page', exact: true, name: 'Revenue', component: Revenue },
    { path: '/admin/doanh-thu/:classId', exact: true, name: 'Revenue', component: Revenue },
    { path: '/admin/hinh-anh-trang-chu', exact: true, name: 'TopBanner', component: TopBanner },
    { path: '/admin/bao-cao-diem-danh/:classId/:page', exact: true, name: 'ReportRollcall', component: ReportRollcall },
    { path: '/admin/bao-cao-diem-danh/:classId', exact: true, name: 'ReportRollcall', component: ReportRollcall },
];

export default routes;
