import React, { Component } from 'react';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestFilePost } from '../../../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import TopMethod from './MethodBanner';
import TopVideo from './TopVideo';
import MethodVideo from './MethodVideo';

export default class TopBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerList: [],
      filePath: null,
      file: null,
    }
    this.image = '';
    this.id = '';
    this.typeImage = 0;

  }

  getbannerList(page) {
    apiRequestGet(`${DOMAIN_MAIN}/banners`).then(result => {
      if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
      else this.setState({ bannerList: result })
    });
  }

  async removeStadium() {
    return await apiRequestPost(`${DOMAIN_MAIN}/banners/remove`, JSON.stringify({ id: this.id, image: this.image })).then(result => {
      if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
      else {
        this.getbannerList();
        NotificationManager.success("Xóa thành công", 'Thành công', 2000);
      }
    });
  }

  async onChangeFile(e) {
    let file = e.target.files[0];
    if (file) {
      if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = await (e => {
          let filePath = e.target.result;
          this.setState({ filePath, file })
        });
      } else {
        NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
      }
    }
  }

  componentDidMount() {
    this.getbannerList();

  }

  saveStadium() {
    const { state } = this;
    if (state.file) {
      apiRequestFilePost(`${DOMAIN_MAIN}/banners/create`, state.file, { typeImage: this.typeImage }).then(result => {
        if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
        else {
          NotificationManager.success("Lưu thành công", 'Thành công', 2000);
          this.getbannerList();
          this.setState({
            file: null,
            filePath: null
          })
        }
      });
    } else {
      NotificationManager.error("Thêm hình ảnh", 'Lỗi', 2000);
    }
  }

  editStadium() {
    const { state } = this;
    const body = {
      id: this.id,
      typeImage: this.typeImage
    }
    let urlRequest = "";
    if (state.file) {
      urlRequest = `${DOMAIN_MAIN}/banners/edit`;
      apiRequestFilePost(urlRequest, state.file, body).then(result => {
        if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
        else {
          NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
          this.getbannerList();
          this.setState({
            file: null,
            filePath: null
          })
        }
      });
    }
  }


  renderModal() {
    const { state } = this;
    return (
      <div className="modal" id="modalCreateStadium">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Thêm mới</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            <div className="modal-body">
              {state.file && <img src={state.filePath} className="img-thumbnail custom-thumbnail"></img>}
              <div className="upload-btn-wrapper">
                <button className="btn btn-upload">Thêm hình ảnh</button>
                <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.saveStadium.bind(this)}>Lưu</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderModalEditStadium() {
    const { state } = this;
    return (
      <div className="modal show" id="modalEditStadium">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Chỉnh sửa huấn luyện viên</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            <div className="modal-body">
              <img ref="imgEditStadium" src={state.filePath} className="img-thumbnail custom-thumbnail"></img>
              <div className="upload-btn-wrapper">
                <button className="btn btn-upload">Thêm hình ảnh</button>
                <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.editStadium.bind(this)} data-dismiss="modal">Chỉnh sửa</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderModalRemoveStadium() {
    return (
      <div className="modal" id="modalRemoveStadium">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Xác nhận xóa</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              Bạn có chắc chắn muốn xóa?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.removeStadium.bind(this)} data-dismiss="modal">Đồng ý</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }

  render() {
    const { state } = this;
    return (
      <div className="dashboard-wrapper">
        {this.renderModalRemoveStadium()}
        {this.renderModalEditStadium()}
        {this.renderModal()}
        <NotificationContainer />
        <div className="container-fluid  dashboard-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="page-header" id="top">
                <div className="page-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Hình ảnh trang chủ</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card">
                <h5 className="card-header">Quản lý hình ảnh đầu trang chủ</h5>
                <div className="card-body border-top">
                  <div><button className="btn btn-success margin-bottom" data-toggle="modal"
                    data-target="#modalCreateStadium" onClick={() => {
                      this.typeImage = 0;
                    }}>Thêm mới</button></div>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Hình Ảnh</th>
                                  <th>Ngày Tạo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.bannerList.filter(banner => !banner.typeImage).map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                      </td>
                                      <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                          this.id = value._id;
                                          this.image = value.image;
                                          this.typeImage = 0;
                                        }}>Chỉnh Sửa</button>
                                        <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveStadium" onClick={() => {
                                          this.image = value.image;
                                          this.id = value._id;
                                        }}>Xóa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TopMethod></TopMethod>
              <TopVideo></TopVideo>
              <MethodVideo id="modalVideoDevelop" title="Quản lý kế hoạch phát triển" typeImage={9} bannerList={state.bannerList.find(banner => banner.typeImage === 9) || {}} getbannerList={() => this.getbannerList()}></MethodVideo>
              <div className="card">
                <h5 className="card-header">Hình ảnh mô tả lời nhắn</h5>
                <div className="card-body border-top">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Hình Ảnh</th>
                                  <th>Ngày Tạo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.bannerList.filter(banner => banner.typeImage == 1).map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                      </td>
                                      <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                          this.id = value._id;
                                          this.image = value.image;
                                          this.typeImage = 1;
                                        }}>Chỉnh Sửa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <h5 className="card-header">Hình ảnh trang phương pháp</h5>
                <div className="card-body border-top">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <h5>Hình ảnh đầu trang</h5>
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Hình Ảnh</th>
                                  <th>Ngày Tạo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.bannerList.filter(banner => banner.typeImage == 5).map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                      </td>
                                      <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                          this.id = value._id;
                                          this.image = value.image;
                                          this.typeImage = 5;
                                        }}>Chỉnh Sửa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <MethodVideo id="modalVideoMethod" title="Quản lý Video trang phương pháp" typeImage={6} bannerList={state.bannerList.find(banner => banner.typeImage === 6) || {}} getbannerList={() => this.getbannerList()}></MethodVideo>
                      <div className="card">
                        <h5 className="card-header">Quản lý hình ảnh hiệu quả phương pháp</h5>
                        <div className="card-body border-top">
                          <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalCreateStadium" onClick={() => {
                            this.typeImage = 7;
                          }}>Thêm mới</button></div>
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="card">
                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                      <thead>
                                        <tr>
                                          <th>Hình Ảnh</th>
                                          <th>Ngày Tạo</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {state.bannerList.filter(banner => banner.typeImage === 7).map((value, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                              </td>
                                              <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                              <td>
                                                <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                                  this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                                  this.id = value._id;
                                                  this.image = value.image;
                                                  this.typeImage = 7;
                                                }}>Chỉnh Sửa</button>
                                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveStadium" onClick={() => {
                                                  this.image = value.image;
                                                  this.id = value._id;
                                                }}>Xóa</button>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <h5>Hình ảnh điểm đặc biệt</h5>
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Hình Ảnh</th>
                                  <th>Ngày Tạo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.bannerList.filter(banner => banner.typeImage == 8).map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                      </td>
                                      <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                          this.id = value._id;
                                          this.image = value.image;
                                          this.typeImage = 8;
                                        }}>Chỉnh Sửa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <h5 className="card-header">Hình ảnh đầu ý kiến</h5>
                <div className="card-body border-top">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Hình Ảnh</th>
                                  <th>Ngày Tạo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.bannerList.filter(banner => banner.typeImage == 2).map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                      </td>
                                      <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                          this.id = value._id;
                                          this.image = value.image;
                                          this.typeImage = 2;
                                        }}>Chỉnh Sửa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <h5 className="card-header">Hình ảnh đầu địa chỉ/học phí</h5>
                <div className="card-body border-top">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Hình Ảnh</th>
                                  <th>Ngày Tạo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.bannerList.filter(banner => banner.typeImage == 3).map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                      </td>
                                      <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                          this.id = value._id;
                                          this.image = value.image;
                                          this.typeImage = 3;
                                        }}>Chỉnh Sửa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <h5 className="card-header">Hình ảnh đầu trải nghiệm miễn phí</h5>
                <div className="card-body border-top">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Hình Ảnh</th>
                                  <th>Ngày Tạo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.bannerList.filter(banner => banner.typeImage == 4).map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                      </td>
                                      <td>{moment(value.createdAt).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                          this.id = value._id;
                                          this.image = value.image;
                                          this.typeImage = 4;
                                        }}>Chỉnh Sửa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}