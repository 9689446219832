import React, { Component } from 'react';
import test from '../../../../resources/images/test.jpg'
import { apiRequestGet } from '../../../../utils/requestHelper';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import './NewDetail.css';
export default class NewDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newDetail: {}
        }
    }
    getNewsDetail(id) {
        apiRequestGet(`${DOMAIN_MAIN}/news/id/${id}`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ newDetail: result })
        });
    }


    componentDidMount() {
        const { props } = this;
        this.getNewsDetail(props.match.params.newId);
    }

    render() {
        const { state } = this;

        let content = state.newDetail.content ? state.newDetail.content.replace(/<p>&lt;iframe/g, "<iframe").replace(/&lt;iframe&gt;<\/p>/g, "</iframe>") : "";
        return (
            <div className="site-section padding-top-30">
                <NotificationContainer />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                {state.newDetail.title ? state.newDetail.title : ""}
                            </h2>
                        </div>
                        <div className="col-12">
                            <span className="color-black">{state.newDetail.created && moment(state.newDetail.created).format("DD/MM/YYYY HH:mm")} </span>
                        </div>
                        <div className="col-12 min-height margin-top container-content-new color-black">{ReactHtmlParser(content)}</div>
                    </div>
                </div>
            </div>
        )
    }
}