import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../constants/requestUrl';
import { apiRequestGet, apiRequestGetAuthen } from '../../../utils/requestHelper';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footer: [],
            banner: {}
        }
    }

    getContactList() {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/footer`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ footer: result })
        });
    }

    getbannerList(page) {
        apiRequestGet(`${DOMAIN_MAIN}/banners/9`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ banner: result })
        });
    }

    componentDidMount() {
        this.getContactList();
        this.getbannerList();
    }

    render() {
        return (
            <footer className="site-footer">
                <NotificationContainer></NotificationContainer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 text-center margin-bottom-15">
                            <h3 className="footer-heading mb-4 text-white text-center margin-bottom-5">Giới thiệu</h3>
                            <div>
                                <a href="/trang-chu"><img className="logo-footer" src={require("../../../resources/images/logobottom.jpg")} alt=""></img></a>
                            </div>
                            <p className="color-white text-center">NaNi Kids Futsal Bóng đá học đường - Ươm mầm thành công</p>
                        </div>
                        <div className="col-md-4 ml-auto">
                            <div className="row">
                                <div className="col-md-12 text-center margin-bottom-5">
                                    <h3 className="footer-heading mb-4 text-white margin-bottom-5">MENU</h3>
                                    <ul className="list-unstyled">
                                        <li><a href="/trang-chu">Trang Chủ</a></li>
                                        <li><a href="/thu-vien-anh">Thư Viện</a></li>
                                        <li><a href={this.state.banner.url} rel="nofollow noopener" target="_blank">Kế Hoạch Phát Triển</a></li>
                                        <li><a href="/tin-tuc">Tin Tức</a></li>
                                        <li><a href="/dang-ky">Đăng Ký</a></li>
                                        <li><a href="/lien-he">Liên Hệ</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>


                        <div className="col-md-4 text-center">
                            <div className="col-md-12"><h3 className="footer-heading mb-4 text-white margin-bottom-5">Kết nối</h3></div>
                            <div className="col-md-12 margin-bottom-15">
                                <p>
                                    <a href="#" className="pb-2 pr-2 pl-0"><span className="icon-facebook"></span></a>
                                    <a href="#" className="p-2"><span className="icon-twitter"></span></a>
                                    <a href="#" className="p-2"><span className="icon-instagram"></span></a>
                                    <a href="#" className="p-2"><span className="icon-vimeo"></span></a>
                                </p>
                            </div>
                            <div className="col-md-12"><h3 className="footer-heading mb-4 text-white margin-bottom-5">Tên công ty</h3></div>
                            <div className="col-md-12 margin-bottom-15 text-white"><p>{this.state.footer.length > 0 && this.state.footer[0].companyName}</p></div>
                            <div className="col-md-12"><h3 className="footer-heading mb-4 text-white margin-bottom-5">Mã số thuế</h3></div>
                            <div className="col-md-12 margin-bottom-15 text-white"><p>{this.state.footer.length > 0 && this.state.footer[0].taxId}</p></div>
                            <div className="col-md-12"><h3 className="footer-heading mb-4 text-white margin-bottom-5">Tài khoản ngân hàng</h3></div>
                            {this.state.footer.length > 0 && this.state.footer[0].bankId.split("/").map((cc) => {
                                return (
                                    <div className="col-md-12 text-white"><p>{cc}</p></div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}