import React, { Component } from 'react';
import { DOMAIN, DOMAIN_MAIN } from '../../../constants/requestUrl';
import user from '../../../resources/images/user.png';
import { apiRequestPost } from '../../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class Header extends Component {
    logout() {
        window.localStorage.removeItem('AccessToken');
        window.localStorage.removeItem('UserInfo');
    }

    editUser() {
        const { props } = this;
        if (this.refs['password1'].value !== '') {
            if (this.refs['password1'].value === this.refs['password2'].value) {
                const body = {
                    username: props.user.username,
                    password: this.refs['password1'].value,
                    role: props.user.role
                }
                apiRequestPost(`${DOMAIN_MAIN}/users/edit`, JSON.stringify(body)).then(result => {
                    if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
                    else {
                        NotificationManager.success("Đổi mật khẩu thành công", 'Thành công', 2000);
                    }
                });
            } else {
                NotificationManager.error("Mật khẩu nhập lại không khớp", 'Lỗi', 2000);
            }
        } else {
            NotificationManager.error("Thêm mật khẩu", 'Lỗi', 2000);
        }
    }

    renderModal() {
        return (
            <div className="modal show" id="modalChangePass">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Đổi mật khẩu</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mật khẩu mới</label>
                                <input autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="off"
                                    ref="password1" type="password" className="form-control" id="exampleFormControlTextarea1" rows="3"></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Nhập lại mật khẩu</label>
                                <input ref="password2" type="password" className="form-control" id="exampleFormControlTextarea1" rows="3"></input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.editUser.bind(this)}>Chỉnh sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <div className="dashboard-header">
                {this.renderModal()}
                <nav className="navbar navbar-expand-lg bg-white fixed-top">
                    <img src={`${DOMAIN}media/logo/logo.jpg`} className="logo-header"></img>

                    <ul className="navbar-nav ml-auto navbar-right-top">
                        <li className="nav-item dropdown nav-user">
                            <a className="nav-link nav-user-img color-white" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src={user} className="img-user"></img>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right nav-user-dropdown" style={{ position: 'absolute' }} aria-labelledby="navbarDropdownMenuLink2">
                                <div className="nav-user-info">
                                    <h5 className="mb-0 text-white nav-user-name">{window.localStorage.getItem('User')} </h5>
                                </div>
                                <a className="dropdown-item" data-toggle="modal" data-target="#modalChangePass"><i className="fas fa-user mr-2"></i>Đổi mật khẩu</a>
                                <a className="dropdown-item" href="/login" onClick={this.logout.bind(this)}><i className="fas fa-power-off mr-2"></i>Đăng xuất</a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}