import './Gallery.css';
import React, { useEffect, useState } from 'react';
import PaginationBt4 from '../../../Shared/PaginationBt4';
import queryString from 'query-string';
import { apiRequestGet } from '../../../../utils/requestHelper';
import { NotificationManager } from 'react-notifications';
import { DOMAIN, DOMAIN_MAIN } from '../../../../constants/requestUrl';
const Gallery = (props) => {
  let params = queryString.parse(props.location.search)
  console.log("params", params)
  const [totalPage, setTotalPage] = useState(15)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(params.page ? Number(params.page) : 1)
  const [type, setType] = useState(params.type || 'image')
  const [activeImage, setActiveImage] = useState({})

  useEffect(() => {
    getGalleryList(type, currentPage)
  }, [])

  const convertImage = (url) => {
    if (!url || url.includes('http')) {
      return url
    } else {
      return `${DOMAIN}${url}`
    }
  }

  const convertThumbnailVideo = (url) => {
    let host = 'https://i.ytimg.com/vi/aaaaaaaaaaa/hqdefault.jpg'
    host = host.replace("aaaaaaaaaaa", url)
    return host
  }

  const renderGalleryModal = () => {
    return (
      <div className="modal show" id="modalGallery">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "calc(100% - 20px)" }}>
          <div className="modal-content">
            <div className="modal-body">
              <div className='title text-center'>{activeImage.title}</div>
              <div className='text-center'>
                {activeImage.typeGallery === 'image' ?
                  <img src={convertImage(activeImage.url)} style={{ height: "80vh" }}></img> :
                  <iframe width="100%" height="600px" src={`https://www.youtube.com/embed/${activeImage.url}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const getGalleryList = (type, page) => {
    setCurrentPage(page)
    setType(type)
    props.history.push(`/thu-vien-anh?type=${type}&page=${page}`)
    apiRequestGet(`${DOMAIN_MAIN}/galleries?${new URLSearchParams({ page, type })}`).then(result => {
      console.log(result)
      if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
      else {
        setTotalPage(result.total)
        setData(result.galleries)
      }
    });
  }

  return (
    <div className="gallery-page">
      {renderGalleryModal()}
      <div className="container">
        <div className='text-center title'>Thư Viện Hình Ảnh Và Video Của Nani Kids Futsal</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={`${type === 'image' ? 'badge-gallery-active' : 'badge-gallery'}`} onClick={() => { getGalleryList('image', 1) }}>Ảnh</div>
          <div className={`${type === 'video' ? 'badge-gallery-active' : 'badge-gallery'}`} onClick={() => { getGalleryList('video', 1) }}>Video</div>
        </div>
        <div className='gallery'>
          {data.map((d, i) => {
            return (
              <a className="dropdown-item" data-toggle="modal" data-target="#modalGallery" style={{ padding: 0 }}>
                <div className='gallery-item' key={i} onClick={() => { setActiveImage(d) }}>
                  <img src={d.typeGallery === 'image' ? convertImage(d.url) : convertThumbnailVideo(d.url)}></img>
                </div>
              </a>
            )
          })}
        </div>
        <div>
          <div className="pagination-highlights mt-5">
            {totalPage > 0 && <PaginationBt4 total={totalPage}
              currentPage={currentPage}
              itemsCountPerPage={10}
              handlePageChange={(i) => { getGalleryList(type, i) }}
            >
            </PaginationBt4>
            }
          </div>
        </div>
        <div class="fb-comments" data-href="https://nanikidsfutsal.com" data-width="100%" data-numposts="20"></div>
      </div>
    </div>
  )
}
export default Gallery;