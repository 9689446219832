import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { DOMAIN, DOMAIN_MAIN } from "../../../../constants/requestUrl";
import { apiRequestFilePost, apiRequestGet, apiRequestPost } from "../../../../utils/requestHelper";

export default class Investor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filePath: null,
      file: null,
      type: 0
    }
    this.id = null
  }
  getInvestorsList() {
    apiRequestGet(`${DOMAIN_MAIN}/investors`).then(result => {
      if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
      else this.setState({ data: result })
    });
  }
  componentDidMount() {
    this.getInvestorsList()
  }
  async onChangeFile(e) {
    let file = e.target.files[0];
    if (file) {
      if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = await (e => {
          let filePath = e.target.result;
          this.setState({ filePath, file })
        });
      } else {
        NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
      }
    }
  }
  saveInvestor() {
    const { state } = this;
    const body = {
      type: this.state.type,
      name: this.refs['name'].value,
      url: this.refs['url'].value,
    }
    if (state.file) {
      apiRequestFilePost(`${DOMAIN_MAIN}/investors/create`, state.file, body).then(result => {
        if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
        else {
          NotificationManager.success("Lưu thành công", 'Thành công', 2000);
          this.getInvestorsList();
          this.refs['name'].value = "";
          this.refs['url'].value = "";
          this.setState({
            file: null,
            filePath: null,
            type: 0
          })
        }
      });
    } else {
      NotificationManager.error("Thêm hình ảnh", 'Lỗi', 2000);
    }
  }
  renderModal() {
    const { state } = this;
    return (
      <div className="modal" id="modalContact">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Thêm mới</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="inputText3" className="col-form-label">Tên nhà tài trợ</label>
                <input ref="name" id="inputText3" type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Url</label>
                <input ref="url" id="inputText3" type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Kiểu tài trợ</label>
                <select className="form-control" id="input-select" onChange={(e) => { this.setState({ type: e.target.value }) }} value={state.type}>
                  <option value='0'>Tài trợ kim cương</option>
                  <option value='1'>Tài trợ bạch kim</option>
                  <option value='2'>Tài trợ vàng</option>
                  <option value='3'>Liên kết đào tạo</option>
                </select>
              </div>
              {state.file && <img src={state.filePath} className="img-thumbnail custom-thumbnail"></img>}
              <div className="upload-btn-wrapper">
                <button className="btn btn-upload">Thêm hình ảnh</button>
                <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={() => { this.saveInvestor() }}>Lưu</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }
  removeStadium() {
    apiRequestPost(`${DOMAIN_MAIN}/investors/remove`, JSON.stringify({ id: this.id, image: this.image })).then(result => {
      if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
      else {
        this.getInvestorsList();
        NotificationManager.success("Xóa thành công", 'Thành công', 2000);
      }
    });
  }
  renderModalRemove() {
    return (
      <div className="modal" id="modalRemove">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Xác nhận xóa</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              Bạn có chắc chắn muốn xóa?
                    </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.removeStadium.bind(this)} data-dismiss="modal">Đồng ý</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }
  render() {
    const { data } = this.state;
    return (
      <div className="dashboard-wrapper">
        {this.renderModal()}
        {this.renderModalRemove()}
        <div className="container-fluid  dashboard-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="page-header">
                <h2 className="pageheader-title">Nhà tài trợ</h2>
                <div className="page-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/admin/chinh-sua-giao-dien" className="breadcrumb-link">Nhà tài trợ</a></li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-body border-top">
                  <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalContact">Thêm mới</button></div>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Nhà tài trợ</th>
                                  <th>Hình ảnh</th>
                                  <th>Url</th>
                                  <th>Loại hình</th>
                                  <th>Chức Năng</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((d, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{d.name}</td>
                                      <td>
                                        <img className="img-table" src={`${DOMAIN}${d.image}`}></img>
                                      </td>
                                      <td>
                                        {d.url}
                                      </td>
                                      <td>
                                        {d.type === 0 && "Tài trợ kim cương"}
                                        {d.type === 1 && "Tài trợ bạch kim"}
                                        {d.type === 2 && "Tài trợ vàng"}
                                        {d.type === 3 && "Liên kết đào tạo"}
                                      </td>
                                      <td>
                                        <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemove" onClick={() => {
                                          this.image = d.image;
                                          this.id = d._id;
                                        }}>Xóa</button>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}