import React, { Component } from 'react';
import test from '../../../../resources/images/test.jpg'
import { apiRequestGet } from '../../../../utils/requestHelper';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import PaginationBt4 from '../../../Shared/PaginationBt4';
export default class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsList: [],
            totalPage: 0,
            currentPage: 1,
        }
    }
    getNewsList(page) {
        apiRequestGet(`${DOMAIN_MAIN}/news/${page}`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ newsList: result.newsList, totalPage: result.total })
        });
    }

    handlePageChange(pageNumber) {
        window.location.href = `#/tin-tuc/${pageNumber}`;
        this.getNewsList(pageNumber);
        this.setState({
            currentPage: pageNumber
        })
    }

    componentDidMount() {
        const { props } = this;
        if (!props.match.params.page) {
            this.getNewsList(1);
        } else {
            this.getNewsList(props.match.params.page);
            this.setState({
                currentPage: Number(props.match.params.page)
            })
        }
    }

    render() {
        const { state } = this;
        return (
            <div className="site-section">
                <NotificationContainer />
                <div className="container">
                    <div className="row mb-5">
                        {state.newsList.map((nl, index) => {
                            return (
                                <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" key={index}>
                                    <div className="media-with-text mb-5">
                                        <div className="mb-4">
                                            <a href={`/tin-tuc/chi-tiet/${nl._id}`}>
                                                <img src={`${DOMAIN}${nl.thumbnail}`} alt="" className="img-fluid" />
                                            </a>
                                        </div>
                                        <h2 className="h5 mb-2"><a href={`/tin-tuc/chi-tiet/${nl._id}`}>{nl.title}</a></h2>
                                        <span className="mb-3 d-block post-date">{moment(nl.created).format("DD/MM/YYYY HH:mm")} </span>
                                        <p>{nl.shortContent}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                            <div className="pagination-highlights">
                                {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                                    currentPage={state.currentPage}
                                    itemsCountPerPage={10}
                                    handlePageChange={this.handlePageChange.bind(this)}
                                >
                                </PaginationBt4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}