import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { DOMAIN, DOMAIN_MAIN } from "../../../../constants/requestUrl";
import { apiRequestGet } from "../../../../utils/requestHelper";
import PaginationBt4 from "../../../Shared/PaginationBt4";
import "./Interview.css";

export default class Interview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interviews: [],
      totalPage: 0,
      page: 1,
      type: "",
      banner: {}
    }
  }
  getInterviewList(page, type) {
    apiRequestGet(`${DOMAIN_MAIN}/interviews?${new URLSearchParams({ page, type })}`).then(result => {
      if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
      else this.setState({ interviews: result.interviews, totalPage: result.total })
    });
  }
  getBanner() {
    apiRequestGet(`${DOMAIN_MAIN}/banners/2`).then(result => {
      if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
      else this.setState({ banner: result })
    });
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const page = Number(urlParams.get('page')) || 1
    const type = urlParams.get('type') ? urlParams.get('type') : ""
    this.setState({
      page,
      type
    })
    this.getInterviewList(page, type)
    this.getBanner();
  }
  handlePageChange(pageNumber) {
    console.log(this.state.type)
    this.props.history.push(`/y-kien?${new URLSearchParams({ page: pageNumber, type: this.state.type || "" })}`)
    this.getInterviewList(pageNumber, this.state.type);
    this.setState({
      page: pageNumber
    })
  }
  render() {
    const { state } = this;
    return (
      <>
        <div className="top-banner-register">
          <img className="img-top" src={`${DOMAIN}${state.banner.image}`}></img>
        </div>
        <div className="py-5 background-img-header">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="site-section-heading text-center text-responsive text-highlight">
                  <img src={require("../../../../resources/images/comment.png")}></img>Ý kiến của phụ huynh và học sinh
                </h3>
              </div>
            </div>
            <div className="row row-small">
              <div className="col-md-4">
                <div className={`type-item ${state.type === "" && "type-item-active"}`} onClick={() => {
                  window.location.href = `/y-kien?${new URLSearchParams({ page: state.page, type: "" })}`
                }}>Tất cả</div>
              </div>
              <div className="col-md-4">
                <div className={`type-item ${state.type === "0" && "type-item-active"}`} onClick={() => {
                  window.location.href = `/y-kien?${new URLSearchParams({ page: state.page, type: 0 })}`
                }}>Phụ huynh</div>
              </div>
              <div className="col-md-4">
                <div className={`type-item ${state.type === "1" && "type-item-active"}`} onClick={() => {
                  window.location.href = `/y-kien?${new URLSearchParams({ page: state.page, type: 1 })}`
                }}>Học sinh</div>
              </div>
            </div>
            {state.interviews.map((interview, j) => {
              return (
                <div className="row row-small interview-item">
                  <div className="col-md-5">
                    <img className="interview-avt" src={`${DOMAIN}${interview.image}`}></img>
                  </div>
                  <div className="col-md-7">
                    <div className="interview-title">{interview.title}</div>
                    {interview.name && <div className="interview-content">
                      <span>Học viên: </span>{interview.name}
                    </div>}
                    {interview.stadium && <div className="interview-content">
                      <span>Cơ sở: </span>{interview.stadium}
                    </div>}
                    {interview.date &&
                      <div className="interview-content">
                        <span>Thời gian học: </span>
                        {interview.date}</div>}
                  </div>
                  {interview.questions.map((question, i) => {
                    return (
                      <div className="col-md-12">
                        <div className="question-item">
                          <div className="question-item-question" onClick={() => {
                            const element = document.getElementById(`answer-${i}-${j}`);
                            if (element.style.display === "none") {
                              element.style.display = "block"
                            } else {
                              element.style.display = "none"
                            }
                          }}>{`Câu hỏi ${i + 1}: ${question.question}`}
                            <img style={{ width: "16px" }} src={require("../../../../resources/images/icondown.png")}></img></div>
                          <div style={{ display: "none" }} className="question-item-answer" id={`answer-${i}-${j}`}>{`Trả lời: ${question.answer}`}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <div className="pagination-highlights">
                  {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                    currentPage={state.page}
                    itemsCountPerPage={10}
                    handlePageChange={this.handlePageChange.bind(this)}
                  >
                  </PaginationBt4>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}