import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { apiRequestGet, apiRequestPost } from '../../../../utils/requestHelper';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import './Register.css';
const locations = [
    {
        name: "Sân bóng đá Thép Việt, đường Nghiêm Xuân Yêm, Q. Ngũ Hành Sơn, TPĐN",
        date: ["Thứ 5 - Từ 18h30 đến 19h30", "Thứ 7 - Từ 16h30 đến 17h30", "Thứ 7 & Chủ Nhật - Từ 16h15 đến 17h30"]
    }, {
        name: "Sân bóng Trưng Vương, 560 đường Trưng Nữ Vương, Q. Hải Châu, TPĐN",
        date: ["Thứ 7 & Chủ Nhật - Từ 6h30 đến 8h00"]
    }, {
        name: "Trung tâm văn hoá thể thao Quận Sơn Trà, số 01 đường Trần Quang Diệu",
        date: ["Thứ 2 & Thứ 6 - Từ 19h30 đến 20h30", "Thứ 7 & Chủ Nhật - Từ 06h30 đến 08h00"]
    },
    {
        name: "Nhà thi đấu Bưu Điện, số 50B Nguyễn Du, Q. Hải Châu, TPĐN",
        date: ["Thứ 3 - Từ 19h00 đến 20h00", "Thứ 3 - Từ 20h00 đến 21h00", "Từ 8h30 đến 10h00"]
    },
    {
        name: "Bóng đá học đường Trường tiểu học, THCS FPT Đà Nẵng",
        date: ["Thứ 2 & Thứ 4 - Từ 16h30 đến 17h30", "Thứ 3 & Thứ 5 - Từ 16h30 đến 17h30"]
    },
    {
        name: "Sân bóng đá Ô Bầu, A2-11 Võ Chí Công ( gần cầu Nguyễn Tri Phương), Q. Cẩm Lệ",
        date: ["Thứ 7 & Chủ Nhật - Từ 6h30 đến 8h00"]
    },
    {
        name: "Sân bóng Nguyễn Chánh, 86 Nguyễn Chánh, Hoà Khánh, Q.Liên Chiểu",
        date: ["Thứ 7 & Chủ Nhật - Từ 16h15 đến 17h30"]
    }
]
const ages = [{ value: 3, name: '3 tuổi' }, { value: 4, name: '4 tuổi' }, { value: 5, name: '5 tuổi' },
{ value: 6, name: '6 tuổi' }, { value: 7, name: '7 tuổi' }, { value: 8, name: '8 tuổi' },
{ value: 9, name: '9 tuổi' }, { value: 10, name: '10 tuổi' }, { value: 11, name: '11 tuổi' }, { value: 12, name: '12 tuổi' },
{ value: 13, name: '13 tuổi' }, { value: 14, name: 'Khác' }]
export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stadiumName: "Sân bóng đá Thép Việt, đường Nghiêm Xuân Yêm, Q. Ngũ Hành Sơn, TPĐN",
            date: "Thứ 5 - Từ 18h30 đến 19h30",
            age: 3,
            banner: {}
        }
    }
    SubmitRegister() {
        const body = {
            studentName: this.refs['studentName'].value,
            parentName: this.refs['parentName'].value,
            address: this.refs['address'].value,
            email: this.refs['email'].value,
            phoneNumber: this.refs['phoneNumber'].value,
            age: this.state.age,
            stadium: this.state.stadiumName,
            date: this.state.date
        }
        if (this.refs['studentName'].value === "") {
            NotificationManager.error("Tên học sinh không được trống", 'Lỗi', 2000);
        } else if (this.refs['parentName'].value === "") {
            NotificationManager.error("Tên phụ huynh không được trống", 'Lỗi', 2000);
        } else if (this.refs['phoneNumber'].value === "") {
            NotificationManager.error("Số điện thoại không được trống", 'Lỗi', 2000);
        } else {
            apiRequestPost(`${DOMAIN_MAIN}/register/create`, JSON.stringify(body)).then(result => {
                if (result.error) NotificationManager.error("Đăng ký không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Đăng ký thành công", 'Thành công', 2000);
                    this.refs['studentName'].value = "";
                    this.refs['parentName'].value = "";
                    this.refs['email'].value = "";
                    this.refs['address'].value = "";
                    this.refs['phoneNumber'].value = "";
                    this.setState({ age: 3 })
                }
            });
        }
    }

    getBanner() {
        apiRequestGet(`${DOMAIN_MAIN}/banners/4`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ banner: result })
        });
    }

    componentDidMount() {
        this.getBanner();
    }

    render() {
        const { stadiumName, date } = this.state;
        const dates = locations.find(s => { return s.name === stadiumName })
        return (
            <>
                <div className="top-banner-register">
                    <img className="img-top" style={{ height: "unset" }} src={`${DOMAIN}${this.state.banner.image}`}></img>
                </div>
                <div className="py-5 background-img-header">
                    <NotificationContainer />
                    <div className="container">
                        <div className="card-container-register">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="site-section-heading text-center text-responsive text-highlight">
                                        <img src={require("../../../../resources/images/register.png")}></img>Đăng ký trải nghiệm&nbsp; <b>MIỄN PHÍ</b>
                                    </h3>
                                </div>
                                <div className="col-md-12 text-center text-description">
                                    Quý phụ huynh có quan tâm xin hãy đến trải nghiệm học thử miễn phí.<br></br>
                                    Mời quý phụ huynh điền vào mẫu đăng ký dưới đây.
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="row form-group">
                                        <div className="col-md-12 mb-3 mb-md-0">
                                            <label className="font-weight-bold" htmlFor="fullname">Họ Tên Phụ Huynh</label>
                                            <input ref="parentName" type="text" id="fullname" className="form-control" placeholder="Họ Tên Phụ Huynh" />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-12">
                                            <label className="font-weight-bold" htmlFor="email">Họ Tên Học Sinh</label>
                                            <input ref="studentName" type="email" id="email" className="form-control" placeholder="Họ Tên Học Sinh" />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-6">
                                            <label className="font-weight-bold" htmlFor="email">Địa điểm học</label>
                                            <select className="form-control" value={stadiumName} onChange={(e) => {
                                                const s = locations.find(l => { return l.name === e.target.value })
                                                this.setState({
                                                    stadiumName: e.target.value,
                                                    date: s.date[0]
                                                })
                                            }}>
                                                {locations.map((l, i) => {
                                                    return (<option value={l.name} key={i}>{l.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="font-weight-bold" htmlFor="email">Thời gian học</label>
                                            <select className="form-control" value={date} onChange={(e) => { this.setState({ date: e.target.value }) }}>
                                                {dates.date.map((d, i) => {
                                                    return (<option value={d} key={i}>{d}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-12 mb-3 mb-md-0">
                                            <label className="font-weight-bold" htmlFor="phone">Số Điện Thoại</label>
                                            <input ref="phoneNumber" type="text" id="phone" className="form-control" placeholder="Số Điện Thoại" />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-12 mb-3 mb-md-0">
                                            <label className="font-weight-bold" htmlFor="phone">Email</label>
                                            <input ref="email" type="email" id="email" className="form-control" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-12 mb-3 mb-md-0">
                                            <label className="font-weight-bold" htmlFor="phone">Độ Tuổi Học Sinh</label>
                                            <div class="row">
                                                {ages.map((age, i) => {
                                                    return (
                                                        <div className="col-md-2">
                                                            <div class="form-check" key={i} onClick={() => { this.setState({ age: age.value }) }}>
                                                                <input type="checkbox" class="form-check-input" checked={this.state.age === age.value}></input>
                                                                <label class="form-check-label" for="exampleCheck1">{age.name}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-12 mb-3 mb-md-0">
                                            <label className="font-weight-bold" htmlFor="phone">Địa Chỉ</label>
                                            <input ref="address" type="text" id="email" className="form-control" placeholder="Địa Chỉ" />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-md-12 text-center">
                                            <input type="button" value="Đăng Ký" className="btn btn-submit text-white px-4 py-2" onClick={this.SubmitRegister.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}