import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost } from '../../../../utils/requestHelper';
import PaginationBt4 from '../../../Shared/PaginationBt4';

export default class Students extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classList: [],
            studentList: [],
            stadiumInfo: '',
            stadiumModalInfo: '',
            totalPage: 0,
            currentPage: this.props.match.params.page ? Number(this.props.match.params.page) : 1,
            name: ''
        }
        this.id = '';
    }

    getClassList() {
        let urlRequest = `${DOMAIN_MAIN}/classes/info`;
        apiRequestGet(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                this.setState({ classList: result });
                let checkClass = result.find(cl => { return this.props.match.params.classId === cl._id });
                if (checkClass) {
                    this.refs['isAdvanced'].value = checkClass.isAdvanced ? "Lớp nâng cao" : "Lớp thường";
                } else {
                    this.refs['isAdvanced'].value = "";
                }
            }
        });
    }

    getStadiumInfo(classId, isModal) {
        if (classId !== 'all') {
            let urlRequest = `${DOMAIN_MAIN}/students/stadium/info/${classId}`;
            apiRequestGet(urlRequest).then(result => {
                if (result && result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
                else isModal ? this.setState({ stadiumModalInfo: result.address ? result.address : "" }) : this.setState({ stadiumInfo: result.address ? result.address : "" })
            });
        } else {
            isModal ? this.setState({ stadiumModalInfo: '' }) : this.setState({ stadiumInfo: '' });
        }
    }

    getStudentsList(classId, pageNumber) {
        const { props } = this;
        const page = pageNumber ? pageNumber : (props.match.params.page ? props.match.params.page : 1);

        let urlRequest = `${DOMAIN_MAIN}/students/page/${page}?name=${this.state.name}`;
        if (classId && classId !== 'all') {
            urlRequest = `${DOMAIN_MAIN}/students/class/${classId}/page/${page}?name=${this.state.name}`;
        }
        else if (props.match.params.classId && classId !== 'all') {
            urlRequest = `${DOMAIN_MAIN}/students/class/${props.match.params.classId}/page/${page}?name=${this.state.name}`;
        }
        apiRequestGet(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ studentList: result.studentsList, totalPage: result.total })
        });
    }

    onChangeSelectStadium(e) {
        const { state } = this;
        this.setState({
            totalPage: 0
        });
        let checkClass = state.classList.find(cl => { return e.target.value === cl._id });
        if (checkClass) {
            this.refs['isAdvanced'].value = checkClass.isAdvanced ? "Lớp nâng cao" : "Lớp thường";
        } else {
            this.refs['isAdvanced'].value = "";
        }
        if (this.props.match.params.page) {
            window.location.href = `/admin/hoc-sinh/${e.target.value}/${this.props.match.params.page}`;
        } else {
            window.location.href = `/admin/hoc-sinh/${e.target.value}`;
        }
    }

    handlePageChange(pageNumber) {
        window.location.href = `/admin/hoc-sinh/${this.props.match.params.classId}/${pageNumber}`;
        this.getStudentsList(this.props.match.params.classId, pageNumber);
        this.setState({
            currentPage: pageNumber
        })
    }

    saveStudent() {
        const body = {
            classId: this.refs['classId'].value,
            studentName: this.refs['studentName'].value,
            parentName: this.refs['parentName'].value,
            age: this.refs['age'].value,
            address: this.refs['address'].value,
            phoneNumber: this.refs['phoneNumber'].value
        }
        apiRequestPost(`${DOMAIN_MAIN}/students/create`, JSON.stringify(body)).then(result => {
            if (result && result.error) NotificationManager.error("Lưu không thành công", 'Lỗi', 2000);
            else {
                NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                this.getStudentsList(this.props.match.params.classId);
                this.refs['studentName'].value = "";
                this.refs['parentName'].value = "";
                this.refs['age'].value = "";
                this.refs['address'].value = "";
                this.refs['phoneNumber'].value = "";
            }
        });
    }

    editStudent() {
        const body = {
            id: this.id,
            studentName: this.refs['studentNameEdit'].value,
            parentName: this.refs['parentNameEdit'].value,
            age: this.refs['ageEdit'].value,
            address: this.refs['addressEdit'].value,
            phoneNumber: this.refs['phoneNumberEdit'].value
        }
        apiRequestPost(`${DOMAIN_MAIN}/students/edit`, JSON.stringify(body)).then(result => {
            if (result && result.error) NotificationManager.error("Chỉnh sửa thành công", 'Lỗi', 2000);
            else {
                this.getStudentsList(this.props.match.params.classId, this.props.match.params.page);
                NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
            }
        });
    }

    async removeStudent() {
        return await apiRequestPost(`${DOMAIN_MAIN}/students/remove`, JSON.stringify({ id: this.id })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getStudentsList(this.props.match.params.classId, this.props.match.params.page);
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.classId !== nextProps.match.params.classId) {
            this.getStadiumInfo(nextProps.match.params.classId);
            this.getStudentsList(nextProps.match.params.classId);
        }
    }

    componentDidMount() {
        this.getClassList();
        this.getStudentsList(this.props.match.params.classId);
        this.getStadiumInfo(this.props.match.params.classId);
    }

    onChangeSelectClass(e) {
        const { state } = this;
        this.getStadiumInfo(e.target.value, true);
        let checkClass = state.classList.find(cl => { return e.target.value === cl._id });
        if (checkClass) {
            this.refs['isAdvancedCreate'].value = checkClass.isAdvanced ? "Lớp nâng cao" : "Lớp thường";
        } else {
            this.refs['isAdvancedCreate'].value = "";
        }
    }

    onShowModal() {
        const { state } = this;
        if (state.classList.length > 0) {
            this.getStadiumInfo(state.classList[0]._id, true);
            this.refs['isAdvancedCreate'].value = state.classList[0].isAdvanced ? "Lớp nâng cao" : "Lớp thường";
        }
    }

    renderModal() {
        const { state } = this;
        return (
            <div className="modal" id="modalCreateStudent">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thêm mới học sinh</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label for="input-select">Danh sách lớp học</label>
                                <select ref="classId" className="form-control" id="input-select" onChange={this.onChangeSelectClass.bind(this)}>
                                    {
                                        state.classList.map((value, index) => {
                                            return (
                                                <option key={index} value={value._id}> {value.description}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label for="input-select">Sân bóng</label>
                                <input id="inputText3" type="text" className="form-control" disabled={true} value={state.stadiumModalInfo} />
                            </div>
                            <div className="form-group">
                                <label for="input-select">Sân bóng</label>
                                <input id="inputText3" type="text" className="form-control" disabled={true} ref="isAdvancedCreate" />
                            </div>
                            <div className="form-group">
                                <label for="inputText3" className="col-form-label">Tên học sinh</label>
                                <input ref="studentName" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Ghi Chú</label>
                                <input ref="parentName" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Độ tuổi</label>
                                <input ref="age" id="inputText3" type="number" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Địa chỉ</label>
                                <input ref="address" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Số điện thoại</label>
                                <input ref="phoneNumber" id="inputText3" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.saveStudent.bind(this)}>Lưu</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalEdit() {
        return (
            <div className="modal" id="modalEditStudent">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thêm mới học sinh</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label for="inputText3" className="col-form-label">Tên học sinh</label>
                                <input ref="studentNameEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Ghi Chú</label>
                                <input ref="parentNameEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Độ tuổi</label>
                                <input ref="ageEdit" id="inputText3" type="number" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Địa chỉ</label>
                                <input ref="addressEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label for="exampleFormControlTextarea1">Số điện thoại</label>
                                <input ref="phoneNumberEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.editStudent.bind(this)}>Chỉnh Sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalRemoveStudent() {
        return (
            <div className="modal" id="modalRemoveStudent">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeStudent.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { state, props } = this;
        const id = props.match.params.classId;
        return (
            <div className="dashboard-wrapper">
                {this.renderModal()}
                {this.renderModalEdit()}
                {this.renderModalRemoveStudent()}
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Học sinh</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Quản lý học sinh</h5>
                                <div className="card-body border-top">
                                    <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalCreateStudent" onClick={this.onShowModal.bind(this)}>Thêm mới</button></div>
                                    <div className="form-group">
                                        <label for="input-select">Danh sách lớp học</label>
                                        <select className="form-control" id="input-select" onChange={this.onChangeSelectStadium.bind(this)} value={id}>
                                            <option value='all'>Tất cả lớp học</option>
                                            {
                                                state.classList.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value._id}> {value.description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="input-select">Sân bóng</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.stadiumInfo} />
                                    </div>
                                    <div className="form-group">
                                        <label for="input-select">Loại lớp</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} ref="isAdvanced" />
                                    </div>
                                    <div className="form-group">
                                        <label for="input-select">Tìm kiếm tên</label>
                                        <div style={{ display: 'flex' }}>
                                            <input id="inputText3" type="text" className="form-control" ref="name" value={state.name} onChange={(e) => {
                                                this.setState({ name: e.target.value })
                                            }} onKeyDown={(event) => {
                                                if (event.keyCode === 13) {
                                                    this.getStudentsList(this.props.match.params.classId, this.props.match.params.page);
                                                }
                                            }} />
                                            <button className="btn btn-upload" onClick={() => {
                                                this.getStudentsList(this.props.match.params.classId, this.props.match.params.page);
                                            }}>Tìm kiếm</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>STT</th>
                                                                    <th>Tên</th>
                                                                    <th>Ghi Chú</th>
                                                                    <th>Tuổi</th>
                                                                    <th>Địa Chỉ</th>
                                                                    <th>Số Điện Thoại</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.studentList.map((st, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{(state.currentPage - 1) * 10 + index + 1}</td>
                                                                            <td>{st.studentName}</td>
                                                                            <td>{st.parentName}</td>
                                                                            <td>{st.age}</td>
                                                                            <td>{st.address}</td>
                                                                            <td>{st.phoneNumber}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStudent" onClick={() => {
                                                                                    this.refs['studentNameEdit'].value = st.studentName;
                                                                                    this.refs['parentNameEdit'].value = st.parentName;
                                                                                    this.refs['ageEdit'].value = st.age;
                                                                                    this.refs['addressEdit'].value = st.address;
                                                                                    this.refs['phoneNumberEdit'].value = st.phoneNumber;
                                                                                    this.id = st._id;
                                                                                }}>Chỉnh Sửa</button>
                                                                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveStudent" onClick={() => {
                                                                                    this.id = st._id;
                                                                                }}>Xóa</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <div className="pagination-highlights">
                                            {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                                                currentPage={state.currentPage}
                                                itemsCountPerPage={10}
                                                handlePageChange={this.handlePageChange.bind(this)}
                                            >
                                            </PaginationBt4>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}