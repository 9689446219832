import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import './PaginationBt4.css'
export default class PaginationBt4 extends Component {
    handlePageChange(pageNumber) {
        this.props.handlePageChange(pageNumber);
    }
    render() {
        const { props } = this;
        return (
            <div className='text-center'>
                <Pagination
                    activePage={props.currentPage}
                    itemsCountPerPage={props.itemsCountPerPage}
                    totalItemsCount={props.total}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                />

            </div>
        )
    }
}