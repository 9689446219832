import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import PaginationBt4 from '../../../Shared/PaginationBt4';
import moment from 'moment';
import DatePicker from "react-datepicker";

export default class DetailRollCall extends Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        const startDate = currentDate.setDate(currentDate.getDate() - 28)
        const endDate = new Date();
        this.state = {
            startDate: props.match.params.startDate ? new Date(moment(props.match.params.startDate)) : startDate,
            endDate: props.match.params.endDate ? new Date(moment(props.match.params.endDate)) : endDate,
            rollCallDate: new Date(),
            totalPage: 0,
            currentPage: props.match.params.page ? Number(props.match.params.page) : 1,
            rollCallList: [],
            studentName: '',
        }
        this.date = ''
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleChangeEndDate = date => {
        this.setState({
            endDate: date
        });
    };

    handleChangeRollCallDate = date => {
        this.setState({
            rollCallDate: date
        });
    };

    getRollCallList(studentId, pageNumber) {
        const { props, state } = this;
        const page = pageNumber ? pageNumber : (props.match.params.page ? props.match.params.page : 1);
        this.props.history.push(`/admin/chi-tiet/diem-danh/${this.props.match.params.studentId}/${page}/${moment(state.startDate).format("YYYY-MM-DD")}/${moment(state.endDate).format("YYYY-MM-DD")}`)
        let urlRequest = `${DOMAIN_MAIN}/students/rollcall/detail/${studentId}/startDate/${moment(state.startDate).format("YYYY-MM-DD")}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}/page/${page}`;
        apiRequestGetAuthen(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ rollCallList: result.studentsList, totalPage: result.total })
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.studentId !== nextProps.match.params.studentId) {
            this.getRollCallList(nextProps.match.params.studentId);
        }
    }

    handlePageChange(pageNumber) {
        this.getRollCallList(this.props.match.params.studentId, pageNumber);
        this.setState({
            currentPage: pageNumber
        })
    }

    rollCallStudent() {
        const { state } = this;
        return apiRequestPost(`${DOMAIN_MAIN}/students/rollcall/date`, JSON.stringify({ studentId: this.props.match.params.studentId, date: moment(state.rollCallDate).format("YYYY-MM-DD") })).then(result => {
            if (result && result.error) NotificationManager.error(result.error, 'Lỗi', 2000);
            else {
                this.getRollCallList(this.props.match.params.studentId, this.props.match.params.page);
            }
        });
    }

    componentDidMount() {
        this.getRollCallList(this.props.match.params.studentId);
    }

    removeRollCall() {
        return apiRequestPost(`${DOMAIN_MAIN}/students/rollcall/remove/date`, JSON.stringify({ studentId: this.props.match.params.studentId, date: this.date })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getRollCallList(this.props.match.params.studentId, this.props.match.params.page);
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    renderModalRemoveRollCall() {
        return (
            <div className="modal" id="modalRemoveRollCall">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeRollCall.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { state, props } = this;
        return (
            <div className="dashboard-wrapper">
                <NotificationContainer />
                {this.renderModalRemoveRollCall()}
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item"><a href="/admin/diem-danh/all/1" className="breadcrumb-link">Điểm danh</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Chi tiết</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Điểm danh</h5>
                                <div className="card-body border-top">
                                    <div className="row">
                                        <div className="container-datepicker col-6">
                                            <label htmlFor="input-select">Ngày điểm danh</label>
                                            <DatePicker
                                                selected={state.rollCallDate}
                                                onChange={this.handleChangeRollCallDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="container-datepicker col-6">
                                            <div className="col-12 text-center margin-top">
                                                <button type="button" className="btn btn-success" onClick={() => { this.rollCallStudent() }}>Điểm Danh</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Chi tiết điểm danh</h5>
                                <div className="card-body border-top">
                                    <div className="form-group">
                                        <label htmlFor="input-select">Tên học sinh</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.rollCallList.studentName ? state.rollCallList.studentName : ""} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Lớp học</label>
                                        <input id="inputText3" type="text" className="form-control" disabled={true} value={state.rollCallList.classInfo ? state.rollCallList.classInfo.className : ""} />
                                    </div>
                                    <div className="row">
                                        <div className="container-datepicker col-6">
                                            <label htmlFor="input-select">Ngày bắt đầu</label>
                                            <DatePicker
                                                selected={state.startDate}
                                                onChange={this.handleChange}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="container-datepicker col-6">
                                            <label htmlFor="input-select">Ngày kết thúc</label>
                                            <DatePicker
                                                selected={state.endDate}
                                                onChange={this.handleChangeEndDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="col-12 text-center margin-top">
                                            <button type="button" className="btn btn-info" onClick={() => { this.getRollCallList(props.match.params.studentId, 1) }}>Tìm kiếm</button>
                                        </div>
                                    </div>
                                    <div className="row margin-top">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Ngày điểm danh</th>
                                                                    <th>Chức năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.rollCallList.classInfo && state.rollCallList.classInfo.rollCall.map((rc, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{moment(rc).format("DD/MM/YYYY")}</td>
                                                                            <td><button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveRollCall" onClick={() => { this.date = moment(rc).format("YYYY-MM-DD") }}>Xóa điểm danh</button></td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <div className="pagination-highlights">
                                            {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                                                currentPage={state.currentPage}
                                                itemsCountPerPage={10}
                                                handlePageChange={this.handlePageChange.bind(this)}
                                            >
                                            </PaginationBt4>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}