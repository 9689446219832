import moment from 'moment';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost } from '../../../../utils/requestHelper';

export default class TopVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerList: {},
        }
    }

    getbannerList() {
        apiRequestGet(`${DOMAIN_MAIN}/top-video`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ bannerList: result || {} })
        });
    }


    componentDidMount() {
        this.getbannerList();

    }

    editStadium() {
        const body = {
            id: this.id || "",
            url: this.refs['url'].value
        }
        let urlRequest = "";
        urlRequest = `${DOMAIN_MAIN}/top-video/edit`;
        apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
            else {
                NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
                this.getbannerList();
                this.refs['url'].value = "";
            }
        });
    }
    rendermodalEditVideo() {
        const { state } = this;
        return (
            <div className="modal show" id="modalEditVideo">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chỉnh sửa phương pháp</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea12">Đường dẫn</label>
                                <input ref="url" className="form-control" id="exampleFormControlTextarea12" rows="3"></input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.editStadium.bind(this)} data-dismiss="modal">Chỉnh sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { state } = this;
        return (
            <>
                {this.rendermodalEditVideo()}
                <NotificationContainer />

                <div className="card">
                    <h5 className="card-header">Quản lý Video trang chủ</h5>
                    <div className="card-body border-top">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Đường dẫn</th>
                                                        <th>Ngày Tạo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td><a href={state.bannerList.url} target="_blank">{state.bannerList.url}</a></td>
                                                        <td>{moment(state.bannerList.createdAt).format("DD-MM-YYYY")}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditVideo" onClick={() => {
                                                                this.refs['url'].value = state.bannerList.url || "";
                                                                this.id = state.bannerList._id;
                                                            }}>Chỉnh Sửa</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}