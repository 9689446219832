import React, { Component } from "react";
import { DOMAIN, DOMAIN_MAIN } from "../../../../constants/requestUrl";
import { apiRequestGet } from "../../../../utils/requestHelper";
import "./Method.css";

export default class Method extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerList: [],
    }
  }

  getbannerList(page) {
    apiRequestGet(`${DOMAIN_MAIN}/banners`).then(result => {
      if (result.error) { }
      else this.setState({ bannerList: result })
    });
  }

  componentDidMount() {
    this.getbannerList();
  }

  render() {
    return (
      <>
        <div className="site-section home-page method-page">
          <div className="half-grid">
            <div className="left">NaNi Kids Futsal hướng đến <b>giáo dục thể chất</b> và <b>kỹ năng sống</b>. Giúp trẻ có định hướng tốt hơn trong tương lai, góp phần thúc đẩy sự phát triển của xã hội</div>
            <div className="right">
              <img src={`${DOMAIN}${this.state.bannerList.find(banner => banner.typeImage == 5) ? this.state.bannerList.find(banner => banner.typeImage == 5).image : ""}`}></img>
            </div>
          </div>
          <div className="method">
            <div className="left">
              <div className="title">Các phương pháp giảng dạy tại NaNi Kids Futsal</div><br></br>
              <p>1. Sân chơi lành mạnh, không ti vi, không internet, không smartphone...</p>
              <p>2. Học cách kiểm soát cảm xúc, khả năng tự tin nói trước đám đông, thủ lĩnh quản lý hành vi</p>
              <p>3. Nâng cao tinh thần đoàn kết, phối hợp đồng đội, nhóm</p>
              <p>4. Biết suy nghĩ và giúp đỡ cho người khác </p>
              <p>5. Khả năng nhìn nhận vấn đề, kích thích sáng tạo, tốc độ xử lý</p>
              <p>6. Phát triển thể chất tối ưu</p>
            </div>
            <div className="right">
              <iframe width="100%" src={this.state.bannerList.find(banner => banner.typeImage == 6) ? this.state.bannerList.find(banner => banner.typeImage == 6).url : ""} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="effective">
            <div className="title">Hiệu quả mang lại từ các phương pháp đào tạo tại NaNi Kids Futsal </div>
            <div className="content">Chúng tôi thông qua việc đào tạo bóng đá, rèn luyện thể chất để giáo dục, phát triển các bé các kỹ năng mềm cần thiết trong cuộc sống</div>
            <div className="content">Sau khóa học các bé sẽ có <b>sức khỏe tốt hơn</b>. Có khả năng <b>tư duy, giải quyết vấn đề</b>. Phát triển <b>tinh thần đoàn kết</b> và nâng cao <b> khả năng thủ lĩnh</b>. Giúp trẻ <b>tránh xa điện thoại, máy tính, những đồ công nghệ</b> ảnh hưởng đến thời gian và tâm lý của trẻ </div>
            <div className="images">
              {this.state.bannerList.filter(banner => banner.typeImage == 7).map((b, i) => {
                return (
                  <img key={i} src={`${DOMAIN}${b.image}`}></img>
                )
              })}
            </div>
          </div>
          <div className="special">
            <div className="title">Điểm đặc biệt tại NaNi Kids Futsal </div>
            <div className="content">Đã đến và là thành viên NaNi Kids Futsal thì tất cả đều là một gia đình. Chúng tôi không những đào tạo và giảng dạy mà còn đồng hành cùng các em phát triển tốt nhất tất cả các tố chất của mình </div>
            <img src={`${DOMAIN}${this.state.bannerList.find(banner => banner.typeImage == 8) ? this.state.bannerList.find(banner => banner.typeImage == 8).image : ""}`}></img>
          </div>
          <div className="row top-banner">
            <a className="banner-content" href="/dang-ky">
              <img className="icon" src={require("../../../../resources/images/banner-icon.png")}></img>
              <div>ĐĂNG KÝ NGAY ĐỂ ĐƯỢC TRẢI NGHIỆM <b>MIỄN PHÍ</b></div>
            </a>
            <img src={require("../../../../resources/images/banner.jpg")}></img>
          </div>
        </div>
      </>
    )
  }
}