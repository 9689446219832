import React, { Component } from 'react';
import Header from './Header/Header';
import NavBar from './NavBar/NavBar';
import Body from './Body/Body';
import { apiRequestGetAuthen } from '../../utils/requestHelper';
import { DOMAIN_MAIN } from '../../constants/requestUrl';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {}
        }
    }
    checkAuthen() {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/users/checkAuthen`).then(result => {
            if (result.error) NotificationManager.error("Lỗi bảo mật", 'Lỗi', 2000);
            else {
                this.setState({
                    user: result
                })
            }
        });
    }

    componentDidMount() {
        this.checkAuthen();
    }

    render() {
        const {state} = this;
        return (
            <div className="dashboard-main-wrapper">
                <NotificationContainer />
                <Header user={state.user}></Header>
                <NavBar user={state.user}></NavBar>
                <Body></Body>
            </div>
        )
    }
}