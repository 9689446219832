import React, { Component } from 'react';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestFilePost, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersList: [],
            filePath: null,
            file: null,
        }
        this.id = '';
    }

    getUsersList() {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/users/get`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ usersList: result })
        });
    }

    async removeUser() {
        return await apiRequestPost(`${DOMAIN_MAIN}/users/remove`, JSON.stringify({ id: this.id })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getUsersList();
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    async onChangeFile(e) {
        let file = e.target.files[0];
        if (file) {
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                let reader = new FileReader();
                await reader.readAsDataURL(file);
                reader.onload = await (e => {
                    let filePath = e.target.result;
                    this.setState({ filePath, file })
                });
            } else {
                NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
            }
        }
    }

    componentDidMount() {
        this.getUsersList();

    }

    saveUser() {
        const { state } = this;
        if (this.refs['password1Create'].value !== '') {
            if (this.refs['password1Create'].value === this.refs['password2Create'].value) {
                const body = {
                    username: this.refs['usernameCreate'].value,
                    password: this.refs['password1Create'].value,
                    role: 1
                }
                apiRequestPost(`${DOMAIN_MAIN}/users/create`, JSON.stringify(body)).then(result => {
                    if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
                    else {
                        NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                        this.getUsersList();
                        this.refs['usernameCreate'].value = "";
                        this.refs['password1Create'].value = "";
                        this.refs['password2Create'].value = "";
                    }
                });
            } else {
                NotificationManager.error("Mật khẩu nhập lại không khớp", 'Lỗi', 2000);
            }
        } else {
            NotificationManager.error("Thêm mật khẩu", 'Lỗi', 2000);
        }
    }

    editStadium() {
        if (this.refs['password1'].value !== '') {
            if (this.refs['password1'].value === this.refs['password2'].value) {
                const body = {
                    username: this.refs['username'].value,
                    password: this.refs['password1'].value,
                    role: 1
                }
                apiRequestPost(`${DOMAIN_MAIN}/users/edit`, JSON.stringify(body)).then(result => {
                    if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
                    else {
                        NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                        this.getUsersList();
                    }
                });
            } else {
                NotificationManager.error("Mật khẩu nhập lại không khớp", 'Lỗi', 2000);
            }
        } else {
            NotificationManager.error("Thêm mật khẩu", 'Lỗi', 2000);
        }
    }


    renderModal() {
        const { state } = this;
        return (
            <div className="modal show" id="modalCreateUser">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Tạo tài khoản nhân viên</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên tài khoản</label>
                                <input autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="off"
                                    ref="usernameCreate" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mật khẩu mới</label>
                                <input autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="off"
                                    ref="password1Create" type="password" className="form-control" id="exampleFormControlTextarea1" rows="3"></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Nhập lại mật khẩu</label>
                                <input ref="password2Create" type="password" className="form-control" id="exampleFormControlTextarea1" rows="3"></input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.saveUser.bind(this)} data-dismiss="modal">Chỉnh sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalEditUser() {
        const { state } = this;
        return (
            <div className="modal show" id="modalEditUser">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chỉnh sửa tài khoản nhân viên</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên tài khoản</label>
                                <input autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="off"
                                    ref="username" id="inputText3" type="text" className="form-control"
                                    disabled={true} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mật khẩu mới</label>
                                <input autoComplete="off"
                                    autoCorrect="off"
                                    spellCheck="off"
                                    ref="password1" type="password" className="form-control" id="exampleFormControlTextarea1" rows="3"></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Nhập lại mật khẩu</label>
                                <input ref="password2" type="password" className="form-control" id="exampleFormControlTextarea1" rows="3"></input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.editStadium.bind(this)} data-dismiss="modal">Lưu</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalRemoveStadium() {
        return (
            <div className="modal" id="modalRemoveStadium">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeUser.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { state } = this;
        return (
            <div className="dashboard-wrapper">
                {this.renderModalRemoveStadium()}
                {this.renderModalEditUser()}
                {this.renderModal()}
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Quản lý tài khoản</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Quản lý tài khoản</h5>
                                <div className="card-body border-top">
                                    <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalCreateUser">Thêm mới</button></div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tài khoản</th>
                                                                    <th>Ngày Tạo</th>
                                                                    <th>Quyền</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.usersList.map((value, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{value.username}</td>
                                                                            <td>{moment(value.created).format("DD-MM-YYYY")}</td>
                                                                            <td>{value.role === 1 ? "Nhân viên" : "Admin"}</td>
                                                                            <td>
                                                                                {value.role === 1 && <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditUser" onClick={() => {
                                                                                    this.refs['username'].value = value.username;
                                                                                    this.id = value._id;
                                                                                }}>Chỉnh Sửa</button>
                                                                                }
                                                                                {value.role === 1 && <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveStadium" onClick={() => {
                                                                                    this.id = value._id;
                                                                                }}>Xóa</button>}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}