import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DOMAIN, DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestFilePost } from '../../../../utils/requestHelper';

export default class CreateNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filePath: null,
            file: null,
            content: ''
        }
    }

    async onChangeFileThumbnail(e) {
        let file = e.target.files[0];
        if (file) {
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                let reader = new FileReader();
                await reader.readAsDataURL(file);
                reader.onload = await (e => {
                    let filePath = e.target.result;
                    this.setState({ filePath, file })
                });
            } else {
                NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
            }
        }
    }

    saveNew() {
        const { state } = this;
        const body = {
            title: this.refs['title'].value,
            shortContent: this.refs['shortContent'].value,
            content: state.content
        }
        if (state.file) {
            apiRequestFilePost(`${DOMAIN_MAIN}/news/create`, state.file, body).then(result => {
                if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                    this.refs['title'].value = "";
                    this.refs['shortContent'].value = "";
                    this.setState({
                        file: null,
                        filePath: null,
                        content: ''
                    })
                }
            });
        } else {
            NotificationManager.error("Thêm hình ảnh", 'Lỗi', 2000);
        }
    }

    render() {
        const { state } = this;
        return (
            <div className="dashboard-wrapper">
                <NotificationContainer></NotificationContainer>
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item"><a href="/admin/tin-tuc" className="breadcrumb-link">Tin tức</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Tạo mới</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Thêm mới tin tức</h5>
                                <div className="card-body border-top">
                                    <div><button className="btn btn-success margin-bottom" onClick={this.saveNew.bind(this)}>Lưu</button></div>
                                    <div className="form-group">
                                        <label htmlFor="inputText3" className="col-form-label">Tựa đề</label>
                                        <input ref="title" id="inputText3" type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputText3" className="col-form-label">Mô tả ngắn</label>
                                        <input ref="shortContent" id="inputText3" type="text" className="form-control" />
                                    </div>
                                    {state.file && <img src={state.filePath} className="img-thumbnail custom-thumbnail"></img>}
                                    <div className="upload-btn-wrapper">
                                        <button className="btn btn-upload">Thêm hình ảnh đại diện</button>
                                        <input type="file" name="myfile" onChange={this.onChangeFileThumbnail.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                                    </div>
                                    <div className="form-group">
                                        <label>Mô tả</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={state.content}
                                            config={{
                                                ckfinder: { uploadUrl: `${DOMAIN_MAIN}/news/upload` }
                                            }}
                                            onInit={editor => {
                                                // You can store the "editor" and use when it is needed.
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.setState({ content: data })
                                            }}
                                            onBlur={(event, editor) => {
                                            }}
                                            onFocus={(event, editor) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}