import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import DatePicker from "react-datepicker";
import { DOMAIN_MAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import moment from 'moment';
import PaginationBt4 from '../../../Shared/PaginationBt4';

export default class Revenue extends Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        const startDate = currentDate.setDate(currentDate.getDate() - 30)
        const endDate = new Date();
        this.state = {
            listPayment: [],
            classList: [],
            totalRevenue: 0,
            totalPage: 0,
            currentPage: 1,
            startDate,
            endDate,
            type: '0'
        }
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleChangeEndDate = date => {
        this.setState({
            endDate: date
        });
    };

    getClassList() {
        let urlRequest = `${DOMAIN_MAIN}/classes/info`;
        apiRequestGet(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                this.setState({ classList: result });
            }
        });
    }

    getPaymentsList(classId, pageNumber, typeParams) {
        const { props, state } = this;
        const page = pageNumber ? pageNumber : (props.match.params.page ? props.match.params.page : 1);
        const type = typeParams ? typeParams : state.type;
        let urlRequest = `${DOMAIN_MAIN}/students/revenue/startDate/${moment(state.startDate).format("YYYY-MM-DD")}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}/type/${type}/page/${page}`;
        if (classId && classId !== 'all') {
            urlRequest = `${DOMAIN_MAIN}/students/revenue/${classId}/startDate/${moment(state.startDate).format("YYYY-MM-DD")}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}/type/${type}/page/${page}`;
        }
        else if (props.match.params.classId && props.match.params.classId !== 'all') {
            urlRequest = `${DOMAIN_MAIN}/students/revenue/${props.match.params.classId}/startDate/${moment(state.startDate).format("YYYY-MM-DD")}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}/type/${type}/page/${page}`;
        }
        apiRequestGetAuthen(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ listPayment: result.listPayment, totalRevenue: result.sumRevenue, totalPage: result.total })
        });
    }

    handlePageChange(pageNumber) {
        window.location.href = `/admin/doanh-thu/${this.props.match.params.classId}/${pageNumber}`;
        this.getPaymentsList(this.props.match.params.classId, pageNumber);
        this.setState({
            currentPage: pageNumber
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.classId !== nextProps.match.params.classId) {
            this.getPaymentsList(nextProps.match.params.classId);
        }
    }

    componentDidMount() {
        this.getClassList();
        this.getPaymentsList(this.props.match.params.classId);
    }

    onChangeSelectStadium(e) {
        const { state } = this;
        this.setState({
            totalPage: 0
        });
        if (this.props.match.params.page) {
            window.location.href = `/admin/doanh-thu/${e.target.value}/${this.props.match.params.page}`;
        } else {
            window.location.href = `/admin/doanh-thu${e.target.value}`;
        }
    }

    onChangeSelectType(e) {
        this.setState({
            type: e.target.value
        });
        this.getPaymentsList(this.props.match.params.classId, this.state.currentPage, e.target.value);
    }

    render() {
        const { state, props } = this;
        const id = props.match.params.classId;
        return (
            <div className="dashboard-wrapper">
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Quản lý doanh thu</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Quản lý doanh thu</h5>
                                <div className="card-body border-top">
                                    <div className="form-group">
                                        <label for="input-select">Danh sách lớp học</label>
                                        <select className="form-control" id="input-select" onChange={this.onChangeSelectStadium.bind(this)} value={id}>
                                            <option value='all'>Tất cả lớp học</option>
                                            {
                                                state.classList.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value._id}> {value.description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="input-select">Phân loại đóng phí</label>
                                        <select className="form-control" id="input-select" onChange={this.onChangeSelectType.bind(this)} value={state.type}>
                                            <option value='0'>Tất cả loại</option>
                                            <option value='1'>Học phí</option>
                                            <option value='2'>Phụ thu</option>
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="container-datepicker col-6">
                                            <label htmlFor="input-select">Ngày bắt đầu</label>
                                            <DatePicker
                                                selected={state.startDate}
                                                onChange={this.handleChange}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="container-datepicker col-6">
                                            <label htmlFor="input-select">Ngày kết thúc</label>
                                            <DatePicker
                                                selected={state.endDate}
                                                onChange={this.handleChangeEndDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group col-12 margin-top">
                                            <label for="input-select">Tổng doanh thu</label>
                                            <input id="inputText3" type="text" className="form-control" disabled={true} value={state.totalRevenue && (state.totalRevenue.toLocaleString() + ' Đồng')} />
                                        </div>
                                        <div className="col-12 text-center margin-top">
                                            <button type="button" className="btn btn-info" onClick={() => { this.getPaymentsList(props.match.params.classId) }}>Tìm kiếm</button>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tên</th>
                                                                    <th>Tên Lớp</th>
                                                                    <th>Phân Loại</th>
                                                                    <th>Ngày Đóng</th>
                                                                    <th>Số Tiền</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.listPayment.map((p, index) => {
                                                                    console.log(p)
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{p.studentName}</td>
                                                                            <td>{p.className}</td>
                                                                            <td>{p.payment.desciption}</td>
                                                                            <td>{moment(p.payment.date).format("DD/MM/YYYY")}</td>
                                                                            <td>{p.payment.charge.toLocaleString()}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <div className="pagination-highlights">
                                                {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                                                    currentPage={state.currentPage}
                                                    itemsCountPerPage={10}
                                                    handlePageChange={this.handlePageChange.bind(this)}
                                                >
                                                </PaginationBt4>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}