import React, { Component } from 'react';
import { DOMAIN_MAIN } from '../../constants/requestUrl';
import { apiRequestPost } from '../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class Login extends Component {

    constructor(props) {
        super(props);
    }

    login(e) {
        e.preventDefault();
        const url = `${DOMAIN_MAIN}/users/login`;
        const body = {
            username: this.refs['username'].value,
            password: this.refs['password'].value
        }
        apiRequestPost(url, JSON.stringify(body)).then(result => {
            if (!result.error) {
                window.localStorage.setItem("AccessToken", result.token);
                window.localStorage.setItem("User", result.username);
                if (result.role === 0) {
                    window.location.href = "/admin";
                } else {
                    window.location.href = "/admin/diem-danh/all/1";
                }
            } else {
                NotificationManager.error(result.error, 'Lỗi', 2000);
            }
        });
    }


    render() {
        return (
            <div className="splash-container">
                <NotificationContainer />
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                            <input ref="username" className="form-control form-control-lg" id="username" type="text" placeholder="Username" autoComplete="on" />
                        </div>
                        <div className="form-group">
                            <input ref="password" className="form-control form-control-lg" id="password" type="password" placeholder="Password" />
                        </div>
                        <button type="button" className="btn btn-primary btn-lg btn-block" onClick={this.login.bind(this)}>Sign in</button>
                    </div>
                </div>
            </div>
        )
    }
}