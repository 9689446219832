import React, { Component } from 'react';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestFilePost } from '../../../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';

export default class Teachers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teachersList: [],
            filePath: null,
            file: null,
        }
        this.image = '';
        this.id = '';
    }

    getTeachersList(page) {
        apiRequestGet(`${DOMAIN_MAIN}/teachers`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ teachersList: result })
        });
    }

    async removeStadium() {
        return await apiRequestPost(`${DOMAIN_MAIN}/teachers/remove`, JSON.stringify({ id: this.id, image: this.image })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getTeachersList();
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    async onChangeFile(e) {
        let file = e.target.files[0];
        if (file) {
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                let reader = new FileReader();
                await reader.readAsDataURL(file);
                reader.onload = await (e => {
                    let filePath = e.target.result;
                    this.setState({ filePath, file })
                });
            } else {
                NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
            }
        }
    }

    componentDidMount() {
        this.getTeachersList();

    }

    saveStadium() {
        const { state } = this;
        const body = {
            name: this.refs['nameStadium'].value,
            description: this.refs['descriptionStadium'].value
        }
        if (state.file) {
            apiRequestFilePost(`${DOMAIN_MAIN}/teachers/create`, state.file, body).then(result => {
                if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                    this.getTeachersList();
                    this.refs['nameStadium'].value = "";
                    this.refs['descriptionStadium'].value = "";
                    this.setState({
                        file: null,
                        filePath: null
                    })
                }
            });
        } else {
            NotificationManager.error("Thêm hình ảnh", 'Lỗi', 2000);
        }
    }

    editStadium() {
        const { state } = this;
        const body = {
            name: this.refs['nameEditStadium'].value,
            description: this.refs['descriptionEditStadium'].value,
            id: this.id,
            image: this.image
        }
        let urlRequest = "";
        if (state.file) {
            urlRequest = `${DOMAIN_MAIN}/teachers/edit`;
            apiRequestFilePost(urlRequest, state.file, body).then(result => {
                if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
                    this.getTeachersList();
                    this.refs['nameEditStadium'].value = "";
                    this.refs['descriptionEditStadium'].value = "";
                    this.setState({
                        file: null,
                        filePath: null
                    })
                }
            });
        } else {
            urlRequest = `${DOMAIN_MAIN}/teachers/edit/no-file`;
            apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
                if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
                    this.getTeachersList();
                    this.refs['nameEditStadium'].value = "";
                    this.refs['descriptionEditStadium'].value = "";
                    this.setState({
                        file: null,
                        filePath: null
                    })
                }
            });
        }
    }


    renderModal() {
        const { state } = this;
        return (
            <div className="modal" id="modalCreateStadium">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thêm mới sân bóng</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên</label>
                                <input ref="nameStadium" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mô tả</label>
                                <textarea ref="descriptionStadium" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            {state.file && <img src={state.filePath} className="img-thumbnail custom-thumbnail"></img>}
                            <div className="upload-btn-wrapper">
                                <button className="btn btn-upload">Thêm hình ảnh</button>
                                <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.saveStadium.bind(this)}>Lưu</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalEditStadium() {
        const { state } = this;
        return (
            <div className="modal show" id="modalEditStadium">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chỉnh sửa huấn luyện viên</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên</label>
                                <input ref="nameEditStadium" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mô tả</label>
                                <textarea ref="descriptionEditStadium" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <img ref="imgEditStadium" src={state.filePath} className="img-thumbnail custom-thumbnail"></img>
                            <div className="upload-btn-wrapper">
                                <button className="btn btn-upload">Thêm hình ảnh</button>
                                <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.editStadium.bind(this)} data-dismiss="modal">Chỉnh sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalRemoveStadium() {
        return (
            <div className="modal" id="modalRemoveStadium">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeStadium.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { state } = this;
        return (
            <div className="dashboard-wrapper">
                {this.renderModalRemoveStadium()}
                {this.renderModalEditStadium()}
                {this.renderModal()}
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Huấn luyện viên</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Quản lý huấn luyện viên</h5>
                                <div className="card-body border-top">
                                    <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalCreateStadium">Thêm mới</button></div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tên</th>
                                                                    <th>Ngày Tạo</th>
                                                                    <th>Hình Ảnh</th>
                                                                    <th>Mô Tả</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.teachersList.map((value, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{value.name}</td>
                                                                            <td>{moment(value.created).format("DD-MM-YYYY")}</td>
                                                                            <td>
                                                                                <img className="img-table" src={`${DOMAIN}${value.image}`}></img>
                                                                            </td>
                                                                            <td>{value.description}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                                                                    this.refs['nameEditStadium'].value = value.name;
                                                                                    this.refs['descriptionEditStadium'].value = value.description;
                                                                                    this.refs['imgEditStadium'].src = `${DOMAIN}${value.image}`;
                                                                                    this.id = value._id;
                                                                                    this.image = value.image;
                                                                                }}>Chỉnh Sửa</button>
                                                                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveStadium" onClick={() => {
                                                                                    this.image = value.image;
                                                                                    this.id = value._id;
                                                                                }}>Xóa</button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}