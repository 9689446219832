import React, { Component } from 'react';
import './Header.css';
export default class Header extends Component {
    render() {
        return (
            <div>
                <div className="site-mobile-menu">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3">
                            <span className="icon-close2 js-menu-toggle"></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body"></div>
                </div>
                <div className="site-navbar-wrap js-site-navbar bg-white padding-none">
                    <div className="container">
                        <div className="site-navbar bg-light">
                            <div className="py-1">
                                <div className="row align-items-center">
                                    <div className="col-2">
                                        <a href="/trang-chu"><img className="logo-header" src={require("../../../resources/images/logobottom.jpg")} alt=""></img></a>
                                    </div>
                                    <div className="col-10">
                                        <nav className="site-navigation text-right" role="navigation">
                                            <div className="container">
                                                <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3"><a href="#" className="site-menu-toggle js-menu-toggle text-black"><span className="icon-menu h3 color-white"></span></a></div>
                                                <ul className="site-menu js-clone-nav d-none d-lg-block">
                                                    <li><a className={window.location.href.includes("trang-chu") ? "active" : ""} href="/trang-chu"><b>Trang chủ</b></a></li>
                                                    <li><a className={window.location.href.includes("thu-vien") ? "active" : ""} href="/thu-vien-anh"><b>Thư viện</b></a></li>
                                                    <li><a className={window.location.href.includes("tin-tuc") ? "active" : ""} href="/tin-tuc"><b>Tin tức</b></a></li>
                                                    <li><a className={window.location.href.includes("phuong-phap") ? "active" : ""} href="/phuong-phap"><b>Phương pháp</b></a></li>
                                                    <li><a className={window.location.href.includes("y-kien") ? "active" : ""} href="/y-kien"><b>Ý kiến</b></a></li>
                                                    <li><a className={`${window.location.href.includes("lien-he") ? "active" : ""}`} href="/lien-he">
                                                        <b>Địa chỉ/Học phí</b>
                                                    </a></li>
                                                    <li><a className={`header-button ${window.location.href.includes("dang-ky") ? "active" : ""}`} href="/dang-ky">
                                                        <img className="icon" src={require("../../../resources/images/star.png")}></img>
                                                        <b>Trải nghiệm miễn phí</b>
                                                    </a></li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>
        );
    }
}