import React, { Component } from 'react';

export default class NavBar extends Component {
    render() {
        const { props } = this;
        const { user } = props;
        return (
            <div className="nav-left-sidebar sidebar-dark">
                <div className="menu-list">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <a className="d-xl-none d-lg-none color-white" href="#">Dashboard</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav flex-column">
                                <li className="nav-divider color-white">
                                    Menu
                                </li>
                                {user.role != undefined && user.role === 0 && <li className="nav-item ">
                                    <a className="nav-link" href="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-1" aria-controls="submenu-1"><i className="fa fa-fw fa-user-circle"></i>Giao diện người dùng <span className="badge badge-success">6</span></a>
                                    <div id="submenu-1" className="collapse submenu">
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/chinh-sua-giao-dien">Giao diện trang chủ</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/tin-tuc">Quản lý tin tức</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/tai-tro">Nhà tài trợ</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/y-kien">Ý kiến phụ huynh-học sinh</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/hinh-anh-trang-chu">Hình ảnh trang chủ</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/quan-ly-thu-vien">Quản lý thử viện</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>}
                                <li className="nav-item">
                                    <a className="nav-link" href="#" data-toggle="collapse" aria-expanded="false" data-target="#submenu-10" aria-controls="submenu-10"><i className="fas fa-f fa-folder"></i>Quản lý hệ thống</a>
                                    <div id="submenu-10" className="collapse submenu">
                                        <ul className="nav flex-column">
                                            {user.role != undefined && user.role === 0 && <li className="nav-item">
                                                <a className="nav-link" href="/admin/san-bong">Quản lý sân bóng</a>
                                            </li>}
                                            {user.role != undefined && user.role === 0 && <li className="nav-item">
                                                <a className="nav-link" href="/admin/huan-luyen-vien">Quản lý huấn luyện viên</a>
                                            </li>}
                                            {user.role != undefined && user.role === 0 && <li className="nav-item">
                                                <a className="nav-link" href="/admin/lop-hoc">Quản lý lớp học</a>
                                            </li>}
                                            {user.role != undefined && user.role === 0 && <li className="nav-item">
                                                <a className="nav-link" href="/admin/hoc-sinh/all/1">Quản lý học sinh</a>
                                            </li>}
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/diem-danh/all/1">Điểm danh</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/hoc-phi/all/1">Quản lý học phí</a>
                                            </li>
                                            {user.role != undefined && user.role === 0 && <li className="nav-item">
                                                <a className="nav-link" href="/admin/dang-ky-moi">Quản lý đăng ký mới</a>
                                            </li>}
                                            {user.role != undefined && user.role === 0 && <li className="nav-item">
                                                <a className="nav-link" href="/admin/tai-khoan">Quản lý tài khoản</a>
                                            </li>}
                                            {user.role != undefined && user.role === 0 && <li className="nav-item">
                                                <a className="nav-link" href="/admin/doanh-thu/all/1">Thống kê doanh thu</a>
                                            </li>}
                                            <li className="nav-item">
                                                <a className="nav-link" href="/admin/bao-cao-diem-danh/all/1">Báo cáo điểm danh</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }
}