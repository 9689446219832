import React, { Component } from 'react';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost } from '../../../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';

export default class Class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classList: [],
            listStadium: [],
            schedule: [],
            classType: "0"
        }
        this.id = '';
    }

    getClassList(stadiumId, classType) {
        const { props } = this;
        let urlRequest = `${DOMAIN_MAIN}/class`;
        if (stadiumId) {
            urlRequest += `/${stadiumId}`;
        }
        else if (props.match.params.stadiumId) {
            urlRequest += `/${props.match.params.stadiumId}`;
        }
        if (classType) {
            urlRequest += `/classType/${classType}`;
        }
        apiRequestGet(urlRequest).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ classList: result })
        });
    }

    getStadiumInfo() {
        apiRequestGet(`${DOMAIN_MAIN}/stadiums/info`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else {
                this.setState({ listStadium: result });
                this.getClassList(this.props.match.params.stadiumId, this.props.match.params.classType);
            }
        });
    }

    async removeClass() {
        return await apiRequestPost(`${DOMAIN_MAIN}/class/remove`, JSON.stringify({ id: this.id })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getClassList(this.props.match.params.stadiumId, this.props.match.params.classType);
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    onChangeClassType(e) {
        this.setState({
            classType: e.target.value
        })
    }

    onChangeSelectStadium(e) {
        if (!this.props.match.params.classType) {
        } else {
            window.location.href = `/admin/lop-hoc/${e.target.value !== '' ? e.target.value + '/' : ''}loai-lop/${this.props.match.params.classType}`;
        }
    }

    onChangeSelectClassType(e) {
        if (!this.props.match.params.stadiumId) {
            window.location.href = `/admin/lop-hoc/loai-lop/${e.target.value}`;
        } else {
            window.location.href = `/admin/lop-hoc/${this.props.match.params.stadiumId}/loai-lop/${e.target.value}`;
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.stadiumId !== nextProps.match.params.stadiumId ||
            this.props.match.params.classType !== nextProps.match.params.classType) {
            this.getClassList(nextProps.match.params.stadiumId, nextProps.match.params.classType);
        }
    }

    componentDidMount() {
        this.getStadiumInfo();
    }

    addSchedule() {
        const { state } = this;
        let schedule = state.schedule;
        const data = {
            day: this.refs["scheduleDay"].value,
            hour: this.refs["scheduleHour"].value,
            minute: this.refs["scheduleMinute"].value,
        }
        schedule.push(data);
        this.setState({
            schedule
        })
    }

    addScheduleEdit() {
        const { state } = this;
        let schedule = state.schedule;
        const data = {
            day: this.refs["scheduleEditDay"].value,
            hour: this.refs["scheduleEditHour"].value,
            minute: this.refs["scheduleEditMinute"].value,
        }
        schedule.push(data);
        this.setState({
            schedule
        })
    }

    removeSchedule(i) {
        const { state } = this;
        let schedule = state.schedule;
        schedule.splice(i, 1);
        this.setState({
            schedule
        })
    }

    SaveClass() {
        const { state } = this;
        const body = {
            stadiumId: this.refs['stadiumClass'].value,
            price: this.refs['priceClass'].value,
            description: this.refs['descriptionClass'].value,
            timeInWeek: state.schedule,
            numberLessonToCharge: this.refs['numberLessonToCharge'].value,
            isAdvanced: this.refs['isAdvanced'].value,
        }
        apiRequestPost(`${DOMAIN_MAIN}/class/create`, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Lưu lớp học không thành công", 'Lỗi', 2000);
            else {
                NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                this.getClassList(this.props.match.params.stadiumId, this.props.match.params.classType);
                this.refs['priceClass'].value = "";
                this.refs['descriptionClass'].value = "";
                this.refs['numberLessonToCharge'].value = "";
                this.setState({
                    schedule: []
                })
            }
        });

    }

    UpdateClass() {
        const { state } = this;
        const body = {
            id: this.id,
            price: this.refs['priceEditClass'].value,
            description: this.refs['descriptionEditClass'].value,
            timeInWeek: state.schedule,
            numberLessonToCharge: this.refs['numberLessonToChargeEdit'].value,
            isAdvanced: this.refs['isAdvancedEdit'].value,
        }
        apiRequestPost(`${DOMAIN_MAIN}/class/edit`, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Chỉnh sửa lớp học không thành công", 'Lỗi', 2000);
            else {
                NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
                this.getClassList(this.props.match.params.stadiumId, this.props.match.params.classType);
            }
        });
    }

    renderModal() {
        const { state } = this;
        return (
            <div className="modal" id="modalCreateClass">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thêm mới lớp học</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="input-select">Danh sách sân bóng</label>
                                <select className="form-control" id="input-select" ref="stadiumClass">
                                    {
                                        state.listStadium.map((value, index) => {
                                            return (
                                                <option key={index} value={value._id}> {value.address}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="input-select">Loại lớp</label>
                                <select className="form-control" id="input-select" ref="isAdvanced" onChange={this.onChangeClassType.bind(this)}>
                                    <option value={false}>Lớp thường</option>
                                    <option value={true}>Lớp nâng cao</option>
                                </select>
                            </div>
                            {state.listStadium.length > 0 && <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên lớp</label>
                                <input ref="descriptionClass" id="inputText3" type="text" className="form-control" />
                            </div>}
                            {state.listStadium.length > 0 && <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Học phí theo tháng (Đồng)</label>
                                <input ref="priceClass" id="inputText3" type="number" className="form-control" />
                            </div>}
                            {state.listStadium.length > 0 && <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Số buổi học</label>
                                <input ref="numberLessonToCharge" id="inputText3" type="number" className="form-control" />
                            </div>}
                            {state.listStadium.length > 0 && <div className="form-group"> <button type="button" className="btn btn-success" onClick={this.addSchedule.bind(this)}>Thêm lịch</button></div>}
                            {state.listStadium.length > 0 && <div className="row">
                                <div className="col-4">
                                    <div className="form-group">
                                        <select className="form-control" id="input-select" ref="scheduleDay">
                                            <option value={2}>thứ 2</option>
                                            <option value={3}>thứ 3</option>
                                            <option value={4}>thứ 4</option>
                                            <option value={5}>thứ 5</option>
                                            <option value={6}>thứ 6</option>
                                            <option value={7}>thứ 7</option>
                                            <option value={8}>CN</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <select className="form-control" id="input-select" ref="scheduleHour">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((value, index) => {
                                            return (
                                                <option key={index} value={value}>{`${value} giờ`}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <select className="form-control" id="input-select" ref="scheduleMinute">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59].map((value, index) => {
                                            return (
                                                <option key={index} value={value}>{`${value} phút`}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>}
                            {state.listStadium.length > 0 &&
                                <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Thời Gian</th>
                                            <th>Chức Năng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.schedule.map((value, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{`${`${value.day}` === "8" ? 'Chủ Nhật' : 'Thứ' + value.day},${value.hour < 10 ? `0${value.hour}` : value.hour}:${value.minute < 10 ? `0${value.minute}` : value.minute}`}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-danger" onClick={this.removeSchedule.bind(this, index)}>Remove</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>}
                        </div>
                        {state.listStadium.length > 0 && <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.SaveClass.bind(this)}>Lưu</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>}

                    </div>
                </div>
            </div>
        )
    }

    renderModalEdit() {
        const { state } = this;
        return (
            <div className="modal" id="modalEditClass">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chỉnh sửa lớp học</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="input-select">Sân bóng</label>
                                <input ref="stadiumEditClass" id="inputText3" type="text" className="form-control" disabled={true} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="input-select">Loại lớp</label>
                                <input ref="isAdvancedEdit" id="inputText3" type="text" className="form-control" disabled={true} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên lớp</label>
                                <input ref="descriptionEditClass" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Học phí theo tháng (Đồng)</label>
                                <input ref="priceEditClass" id="inputText3" type="number" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Số buổi học</label>
                                <input ref="numberLessonToChargeEdit" id="inputText3" type="number" className="form-control" disabled={true} />
                            </div>
                            <div className="form-group"> <button type="button" className="btn btn-success" onClick={this.addScheduleEdit.bind(this)}>Thêm lịch</button></div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-group">
                                        <select className="form-control" id="input-select" ref="scheduleEditDay">
                                            <option value={2}>thứ 2</option>
                                            <option value={3}>thứ 3</option>
                                            <option value={4}>thứ 4</option>
                                            <option value={5}>thứ 5</option>
                                            <option value={6}>thứ 6</option>
                                            <option value={7}>thứ 7</option>
                                            <option value={8}>CN</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <select className="form-control" id="input-select" ref="scheduleEditHour">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((value, index) => {
                                            return (
                                                <option key={index} value={value}>{`${value} giờ`}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <select className="form-control" id="input-select" ref="scheduleEditMinute">
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59].map((value, index) => {
                                            return (
                                                <option key={index} value={value}>{`${value} phút`}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Thời Gian</th>
                                        <th>Chức Năng</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.schedule.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{`${`${value.day}` === "8" ? 'Chủ Nhật' : 'Thứ' + value.day},${value.hour < 10 ? `0${value.hour}` : value.hour}:${value.minute < 10 ? `0${value.minute}` : value.minute}`}</td>
                                                <td>
                                                    <button type="button" className="btn btn-danger" onClick={this.removeSchedule.bind(this, index)}>Remove</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.UpdateClass.bind(this)}>Chỉnh Sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalRemoveClass() {
        return (
            <div className="modal" id="modalRemoveClass">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeClass.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { state, props } = this;
        const id = props.match.params.stadiumId;
        const classType = props.match.params.classType ? props.match.params.classType : '-1';
        return (
            <div className="dashboard-wrapper">
                {this.renderModal()}
                {this.renderModalRemoveClass()}
                {this.renderModalEdit()}
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Lớp học</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Quản lý lớp học</h5>
                                <div className="card-body border-top">
                                    <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalCreateClass" onClick={() => { this.setState({ schedule: [] }) }}>Thêm mới</button></div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Danh sách sân bóng</label>
                                        <select className="form-control" id="input-select" onChange={this.onChangeSelectStadium.bind(this)} value={id}>
                                            <option value=''>Tất cả sân bóng</option>
                                            {
                                                state.listStadium.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value._id}> {value.address}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="input-select">Loại lớp học</label>
                                        <select className="form-control" id="input-select" onChange={this.onChangeSelectClassType.bind(this)} value={classType}>
                                            <option value='-1'>Tất cả sân bóng</option>
                                            <option value='0'>Lớp thường</option>
                                            <option value='1'>Lớp nâng cao</option>
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Lịch Học</th>
                                                                    <th>Tên Lớp</th>
                                                                    <th>Ngày Tạo</th>
                                                                    <th>Học Phí</th>
                                                                    <th>Sân Bóng</th>
                                                                    <th>Loại Lớp</th>
                                                                    <th>Số Buổi</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.classList.map((cl) => {
                                                                    return cl.class.map((value, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{value.timeInWeek.map((v, i) => {
                                                                                    return (
                                                                                        <p>{`${`${v.day}` === "8" ? 'Chủ Nhật' : 'Thứ' + v.day},${v.hour < 10 ? `0${v.hour}` : v.hour}:${v.minute < 10 ? `0${v.minute}` : v.minute}`}{i !== v.length - 1 && <br></br>}</p>
                                                                                    )
                                                                                })}</td>
                                                                                <td>{value.description}</td>
                                                                                <td>{moment(value.created).format("DD-MM-YYYY")}</td>
                                                                                <td>{value.price}</td>
                                                                                <td>{cl.address}</td>
                                                                                <td>{!value.isAdvanced ? 'Lớp thường' : 'Lớp nâng cao'}</td>
                                                                                <td>{value.numberLessonToCharge}</td>
                                                                                <td>
                                                                                    <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditClass" onClick={() => {
                                                                                        this.refs['stadiumEditClass'].value = cl.address;
                                                                                        this.refs['descriptionEditClass'].value = value.description;
                                                                                        this.refs['priceEditClass'].value = value.price;
                                                                                        this.refs['isAdvancedEdit'].value = `${value.isAdvanced}` === '0' ? 'Lớp thường' : 'Lớp nâng cao';
                                                                                        this.refs['numberLessonToChargeEdit'].value = value.numberLessonToCharge;
                                                                                        this.setState({ schedule: [...value.timeInWeek] });
                                                                                        this.id = value._id;
                                                                                    }}>Chỉnh sửa</button>
                                                                                    <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveClass" onClick={() => {
                                                                                        this.id = value._id;
                                                                                    }}>Xóa</button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}