import React, { Component } from 'react';
import './UIManager.css';
import headerImage from '../../../../resources/images/header.jpg';
import FileSaver from 'file-saver';
import { apiRequestFilePost, apiRequestGet, apiRequestGetAuthen, apiRequestPost } from '../../../../utils/requestHelper';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class UIManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filePath: null,
            file: null,
            filePathLogo: null,
            fileLogo: null,
            filePathBackground: null,
            fileBackground: null,
            fileFunction: null,
            filePathFunction: null,
            uploaded: false,
            uploadedLogo: false,
            uploadedBackground: false,
            dataFunction: [],
            dataContact: []
        }
        this.idEdit = '';
        this.urlImage = '';
        this.idContact = '';
    }

    async onChangeBackground(e) {
        let file = e.target.files[0];
        if (file) {
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                let reader = new FileReader();
                await reader.readAsDataURL(file);
                reader.onload = await (e => {
                    let filePath = e.target.result;
                    this.setState({ filePathBackground: filePath, fileBackground: file, uploadedBackground: true });
                });
            } else {
                NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
            }
        }
    }

    async onChangeFile(uploadLogo, e) {
        let file = e.target.files[0];
        if (file) {
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                let reader = new FileReader();
                await reader.readAsDataURL(file);
                reader.onload = await (e => {
                    let filePath = e.target.result;
                    if (!uploadLogo) {
                        this.setState({ filePath, file, uploaded: true });
                    } else {
                        this.setState({ filePathLogo: filePath, fileLogo: file, uploadedLogo: true });
                    }
                });
            } else {
                NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
            }
        }
    }

    clearFile(uploadLogo) {
        if (!uploadLogo) {
            this.setState({ uploaded: false, file: null, filePath: null });
        } else {
            this.setState({ uploadedLogo: false, fileLogo: null, filePathLogo: null });
        }
    }

    clearFileBackground() {
        this.setState({ uploadedBackground: false, file: null, filePathBackgound: null });
    }

    saveFileBackground() {
        const { state } = this;
        let urlRequest = '';
        urlRequest = `${DOMAIN_MAIN}/uiclient/background`;
        apiRequestFilePost(urlRequest, state.fileBackground).then(result => {
            if (result.error) NotificationManager.error("Lưu ảnh không thành công", 'Lỗi', 2000);
            else { NotificationManager.success("Lưu ảnh thành công", 'Thành công', 2000); this.setState({ uploadedBackground: false }); }
        });
    }

    saveFile(uploadLogo) {
        const { state } = this;
        let urlRequest = '';
        if (!uploadLogo) {
            urlRequest = `${DOMAIN_MAIN}/uiclient/imgheader`;
        } else {
            urlRequest = `${DOMAIN_MAIN}/uiclient/logo`;
        }
        apiRequestFilePost(urlRequest, !uploadLogo ? state.file : state.fileLogo).then(result => {
            if (result.error) NotificationManager.error("Lưu ảnh không thành công", 'Lỗi', 2000);
            else { NotificationManager.success("Lưu ảnh thành công", 'Thành công', 2000); !uploadLogo ? this.setState({ uploaded: false }) : this.setState({ uploadedLogo: false }); }
        });
    }

    getFunctionList() {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/functions`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ dataFunction: result })
        });
    }


    async onChangeFileFunction(e) {
        let fileFunction = e.target.files[0];
        if (fileFunction) {
            if (fileFunction.type === "image/png" || fileFunction.type === "image/jpg" || fileFunction.type === "image/jpeg") {
                let reader = new FileReader();
                await reader.readAsDataURL(fileFunction);
                reader.onload = await (e => {
                    let filePathFunction = e.target.result;
                    this.setState({ filePathFunction, fileFunction })
                });
            } else {
                NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
            }
        }
    }

    saveFunction() {
        const { state } = this;
        const body = {
            title: this.refs['titleFunction'].value,
            description: this.refs['descriptionFunction'].value
        }
        if (state.fileFunction) {
            apiRequestFilePost(`${DOMAIN_MAIN}/functions/create`, state.fileFunction, body).then(result => {
                if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                    this.getFunctionList();
                    this.refs['titleFunction'].value = "";
                    this.refs['descriptionFunction'].value = "";
                    this.setState({
                        fileFunction: null,
                        filePathFunction: null
                    })
                }
            });
        } else {
            NotificationManager.error("Thêm hình ảnh", 'Lỗi', 2000);
        }
    }

    editFunction() {
        const { state } = this;
        const body = {
            title: this.refs['titleEditFunction'].value,
            description: this.refs['descriptionEditFunction'].value,
            id: this.idEdit,
            urlImage: this.urlImage
        }
        let urlRequest = "";
        if (state.fileFunction) {
            urlRequest = `${DOMAIN_MAIN}/functions/edit`;
            apiRequestFilePost(urlRequest, state.fileFunction, body).then(result => {
                if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
                    this.getFunctionList();
                    this.refs['titleEditFunction'].value = "";
                    this.refs['descriptionEditFunction'].value = "";
                    this.setState({
                        fileFunction: null,
                        filePathFunction: null
                    })
                }
            });
        } else {
            urlRequest = `${DOMAIN_MAIN}/functions/edit/no-file`;
            apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
                if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
                else {
                    NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
                    this.getFunctionList();
                    this.refs['titleEditFunction'].value = "";
                    this.refs['descriptionEditFunction'].value = "";
                    this.setState({
                        fileFunction: null,
                        filePathFunction: null
                    })
                }
            });
        }
    }

    removeFunction() {
        apiRequestPost(`${DOMAIN_MAIN}/functions/remove`, JSON.stringify({ id: this.idEdit, urlImage: this.urlImage })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getFunctionList();
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }
    //render component
    renderModal() {
        const { state } = this;
        return (
            <div className="modal" id="modalFunction">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thêm mới mô tả chức năng</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tựa đề</label>
                                <input ref="titleFunction" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mô tả</label>
                                <textarea ref="descriptionFunction" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            {state.fileFunction && <img src={state.filePathFunction} className="img-thumbnail custom-thumbnail"></img>}
                            <div className="upload-btn-wrapper">
                                <button className="btn btn-upload">Thêm hình ảnh</button>
                                <input type="file" name="myfile" onChange={this.onChangeFileFunction.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.saveFunction.bind(this)}>Lưu</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalEditFunction() {
        const { state } = this;
        return (
            <div className="modal show" id="modalEditFunction">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chỉnh sửa mô tả chức năng</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tựa đề</label>
                                <input ref="titleEditFunction" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mô tả</label>
                                <textarea ref="descriptionEditFunction" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <img ref="imgEditFunction" src={state.filePathFunction} className="img-thumbnail custom-thumbnail"></img>
                            <div className="upload-btn-wrapper">
                                <button className="btn btn-upload">Thêm hình ảnh</button>
                                <input type="file" name="myfile" onChange={this.onChangeFileFunction.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.editFunction.bind(this)} data-dismiss="modal">Chỉnh sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalRemoveFunction() {
        return (
            <div className="modal" id="modalRemoveFunction">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeFunction.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    getContactList() {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/contact`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ dataContact: result })
        });
    }

    saveContact() {
        const body = {
            address: this.refs['addressContact'].value,
            phoneNumber: this.refs['phoneNumberContact'].value,
            email: this.refs['emailContact'].value,
            companyName: this.refs['companyNameContact'].value,
            taxId: this.refs['taxIdContact'].value,
            bankId: this.refs['bankIdContact'].value,
            description: this.refs['descriptionContact'].value,
            linkVideo: this.refs['linkVideoContact'].value
        }
        apiRequestPost(`${DOMAIN_MAIN}/contact/create`, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
            else {
                NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                this.getContactList();
                this.refs['addressContact'].value = "";
                this.refs['phoneNumberContact'].value = "";
                this.refs['emailContact'].value = "";
                this.refs['companyNameContact'].value = "";
                this.refs['taxIdContact'].value = "";
                this.refs['bankIdContact'].value = "";
                this.refs['descriptionContact'].value = "";
                this.refs['linkVideoContact'].value = "";
            }
        });
    }

    editContact() {
        const body = {
            id: this.idContact,
            address: this.refs['addressContactEdit'].value,
            phoneNumber: this.refs['phoneNumberContactEdit'].value,
            email: this.refs['emailContactEdit'].value,
            companyName: this.refs['companyNameContactEdit'].value,
            taxId: this.refs['taxIdContactEdit'].value,
            bankId: this.refs['bankIdContactEdit'].value,
            description: this.refs['descriptionContactEdit'].value,
            linkVideo: this.refs['linkVideoContactEdit'].value
        }
        apiRequestPost(`${DOMAIN_MAIN}/contact/edit`, JSON.stringify(body)).then(result => {
            if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
            else {
                NotificationManager.success("Lưu thành công", 'Thành công', 2000);
                this.getContactList();
            }
        });
    }

    removeContact() {
        apiRequestPost(`${DOMAIN_MAIN}/contact/remove`, JSON.stringify({ id: this.idContact })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getContactList();
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    renderModalContact() {
        return (
            <div className="modal" id="modalContact">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Thêm mới liên hệ</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên công ty</label>
                                <input ref="companyNameContact" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mã số thuế</label>
                                <input ref="taxIdContact" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Số tài khoản</label>
                                <input ref="bankIdContact" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mục tiêu chính</label>
                                <textarea ref="descriptionContact" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Địa chỉ</label>
                                <input ref="addressContact" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Số điện thoại</label>
                                <input ref="phoneNumberContact" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Email</label>
                                <input ref="emailContact" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Video Trang chủ</label>
                                <input ref="linkVideoContact" id="inputText3" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.saveContact.bind(this)}>Lưu</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalEditContact() {
        return (
            <div className="modal show" id="modalEditContact">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Chỉnh sửa liên hệ</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Tên công ty</label>
                                <input ref="companyNameContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mã số thuế</label>
                                <input ref="taxIdContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Số tài khoản</label>
                                <input ref="bankIdContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Mục tiêu chính</label>
                                <textarea ref="descriptionContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputText3" className="col-form-label">Địa chỉ</label>
                                <input ref="addressContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Số điện thoại</label>
                                <input ref="phoneNumberContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Email</label>
                                <input ref="emailContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Video Trang chủ</label>
                                <input ref="linkVideoContactEdit" id="inputText3" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.editContact.bind(this)}>Chỉnh Sửa</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderModalRemoveContact() {
        return (
            <div className="modal" id="modalRemoveContact">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeContact.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getFunctionList();
        this.getContactList();
    }

    render() {
        const { state, props } = this;
        return (
            <div className="dashboard-wrapper">
                <NotificationContainer />
                {this.renderModalEditFunction()}
                {this.renderModal()}
                {this.renderModalRemoveFunction()}
                {this.renderModalContact()}
                {this.renderModalEditContact()}
                {this.renderModalRemoveContact()}
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header">
                                <h2 className="pageheader-title">Chỉnh sửa giao diện</h2>
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/chinh-sua-giao-dien" className="breadcrumb-link">Chỉnh sửa giao diện</a></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Hình ảnh trang chủ</h5>
                                <div className="card-body text-center">
                                    <div className="container-thumbnail">
                                        <img src={state.file ? state.filePath : `${DOMAIN}media/header/img-header.jpg`} className="img-thumbnail custom-thumbnail"></img>
                                    </div>
                                    {state.uploaded && <div className="upload-btn-wrapper">
                                        <button type="button" className="btn btn-success" onClick={this.saveFile.bind(this, false)}>Lưu</button>
                                    </div>}
                                    <div className="upload-btn-wrapper">
                                        <button className="btn btn-upload">Chỉnh sửa</button>
                                        <input type="file" name="myfile" onChange={this.onChangeFile.bind(this, false)} accept="image/jpg, image/jpeg, image/png" />
                                    </div>
                                    {state.uploaded && <div className="upload-btn-wrapper">
                                        <button type="button" className="btn btn-warning" onClick={this.clearFile.bind(this, false)}>Xóa</button>
                                    </div>}
                                </div>
                                <h5 className="card-header">Quản lý logo</h5>
                                <div className="card-body border-top">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Logo</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <img className="img-table" src={state.filePathLogo ? state.filePathLogo : `${DOMAIN}media/logo/logo.jpg`}></img>
                                                                    </td>
                                                                    <td>
                                                                        {state.uploadedLogo && <div className="upload-btn-wrapper">
                                                                            <button type="button" className="btn btn-success" onClick={this.saveFile.bind(this, true)}>Lưu</button>
                                                                        </div>}
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="btn btn-upload">Chỉnh sửa</button>
                                                                            <input type="file" name="myfile" onChange={this.onChangeFile.bind(this, true)} accept="image/jpg, image/jpeg, image/png" />
                                                                        </div>
                                                                        {state.uploadedLogo && <div className="upload-btn-wrapper">
                                                                            <button type="button" className="btn btn-warning" onClick={this.clearFile.bind(this)}>Xóa</button>
                                                                        </div>}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="card-header">Quản lý hình nền</h5>
                                <div className="card-body border-top">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Hình nền</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <img className="img-table" src={state.filePathBackground ? state.filePathBackground : `${DOMAIN}media/background/background.jpg`}></img>
                                                                    </td>
                                                                    <td>
                                                                        {state.uploadedBackground && <div className="upload-btn-wrapper">
                                                                            <button type="button" className="btn btn-success" onClick={this.saveFileBackground.bind(this)}>Lưu</button>
                                                                        </div>}
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="btn btn-upload">Chỉnh sửa</button>
                                                                            <input type="file" name="myfile" onChange={this.onChangeBackground.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                                                                        </div>
                                                                        {state.uploadedBackground && <div className="upload-btn-wrapper">
                                                                            <button type="button" className="btn btn-warning" onClick={this.clearFileBackground.bind(this)}>Xóa</button>
                                                                        </div>}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="card-header">Liên hệ</h5>
                                <div className="card-body border-top">
                                    <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalContact">Thêm mới</button></div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Địa Chỉ</th>
                                                                    <th>Số Điện Thoại</th>
                                                                    <th>Email</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.dataContact.map((value, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{value.address}</td>
                                                                            <td>{value.phoneNumber}</td>
                                                                            <td>{value.email}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditContact" onClick={() => {
                                                                                    this.refs['addressContactEdit'].value = value.address;
                                                                                    this.refs['phoneNumberContactEdit'].value = value.phoneNumber;
                                                                                    this.refs['emailContactEdit'].value = value.email;
                                                                                    this.refs['companyNameContactEdit'].value = value.companyName;
                                                                                    this.refs['taxIdContactEdit'].value = value.taxId;
                                                                                    this.refs['bankIdContactEdit'].value = value.bankId;
                                                                                    this.refs['descriptionContactEdit'].value = value.description;
                                                                                    this.refs['linkVideoContactEdit'].value = value.linkVideo
                                                                                    this.idContact = value._id;
                                                                                }}>Edit</button>
                                                                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveContact" onClick={() => {
                                                                                    this.idContact = value._id
                                                                                }}>Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="card-header">Quản lý hoạt động</h5>
                                <div className="card-body border-top">
                                    <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalFunction">Thêm mới</button></div>
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tựa Đề</th>
                                                                    <th>Mô Tả</th>
                                                                    <th>Hình Ảnh</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.dataFunction.map((value, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{value.title}</td>
                                                                            <td>{value.description}</td>
                                                                            <td>
                                                                                <img className="img-table" src={`${DOMAIN}${value.urlImage}`}></img>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditFunction" onClick={() => {
                                                                                    this.refs['titleEditFunction'].value = value.title;
                                                                                    this.refs['descriptionEditFunction'].value = value.description;
                                                                                    this.refs['imgEditFunction'].src = `${DOMAIN}${value.urlImage}`;
                                                                                    this.idEdit = value._id;
                                                                                    this.urlImage = value.urlImage;
                                                                                }}>Edit</button>
                                                                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveFunction" onClick={() => {
                                                                                    this.urlImage = value.urlImage;
                                                                                    this.idEdit = value._id
                                                                                }}>Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}