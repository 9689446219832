import React, { Component } from 'react';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestFilePost } from '../../../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';

export default class Galleries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stadiumsList: [],
      filePath: null,
      file: null,
      type: 'image'
    }
    this.image = '';
    this.id = '';
  }

  getStadiumsList(page) {
    apiRequestGet(`${DOMAIN_MAIN}/galleries`).then(result => {
      if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
      else this.setState({ stadiumsList: result.galleries })
    });
  }

  async removeStadium() {
    return await apiRequestPost(`${DOMAIN_MAIN}/galleries`, JSON.stringify({ id: this.id, image: this.image }), '', 'DELETE').then(result => {
      if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
      else {
        this.getStadiumsList();
        NotificationManager.success("Xóa thành công", 'Thành công', 2000);
      }
    });
  }

  async onChangeFile(e) {
    let file = e.target.files[0];
    if (file) {
      if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        let reader = new FileReader();
        await reader.readAsDataURL(file);
        reader.onload = await (e => {
          let filePath = e.target.result;
          this.setState({ filePath, file })
        });
      } else {
        NotificationManager.error("Định dạng file ảnh không đúng!", 'Lỗi', 2000);
      }
    }
  }

  componentDidMount() {
    this.getStadiumsList();

  }

  saveStadium() {
    const { state } = this;
    const body = {
      title: this.refs['title'].value,
      type: state.type,
      url: this.refs['url'].value
    }
    if (state.file && state.type === 'image') {
      apiRequestFilePost(`${DOMAIN_MAIN}/galleries`, state.file, body, 'POST', 'files').then(result => {
        if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
        else {
          NotificationManager.success("Lưu thành công", 'Thành công', 2000);
          this.getStadiumsList();
          this.refs['title'].value = "";
          this.setState({ type: 'image' })
          this.setState({
            file: null,
            filePath: null
          })
        }
      });
    } else {
      apiRequestPost(`${DOMAIN_MAIN}/galleries/no-file`, JSON.stringify(body), '', 'POST').then(result => {
        if (result.error) NotificationManager.error("Lưu chức năng không thành công", 'Lỗi', 2000);
        else {
          NotificationManager.success("Lưu thành công", 'Thành công', 2000);
          this.getStadiumsList();
          this.refs['title'].value = "";
          this.refs['url'].value = "";
          this.setState({ type: 'image' })
          this.setState({
            file: null,
            filePath: null
          })
        }
      });
    }
  }

  editStadium() {
    const { state } = this;
    const body = {
      title: this.refs['title-edit'].value,
      type: state.type,
      url: this.refs['url-edit'].value,
      id: this.id
    }
    let urlRequest = "";
    if (state.file && state.type === 'image') {
      urlRequest = `${DOMAIN_MAIN}/galleries/edit`;
      apiRequestFilePost(urlRequest, state.file, body).then(result => {
        if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
        else {
          NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
          this.getStadiumsList();
          this.refs['title-edit'].value = "";
          this.refs['url-edit'].value = "";
          this.setState({
            file: null,
            filePath: null,
            type: 'image'
          })
        }
      });
    } else {
      urlRequest = `${DOMAIN_MAIN}/galleries/edit/no-file`;
      apiRequestPost(urlRequest, JSON.stringify(body)).then(result => {
        if (result.error) NotificationManager.error("Chỉnh sửa không thành công", 'Lỗi', 2000);
        else {
          NotificationManager.success("Chỉnh sửa thành công", 'Thành công', 2000);
          this.getStadiumsList();
          this.refs['title-edit'].value = "";
          this.refs['url-edit'].value = "";
          this.setState({
            file: null,
            filePath: null,
            type: 'image'
          })
        }
      });
    }
  }


  renderModal() {
    const { state } = this;
    return (
      <div className="modal" id="modalCreateStadium">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Thêm mới thư viện</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <input type="checkbox" id="html" value="image" checked={this.state.type === 'image'} onClick={() => {
                this.setState({ type: 'image' })
              }} />&nbsp;&nbsp;&nbsp;&nbsp;
              <label for="html">Ảnh</label><br />
              <input type="checkbox" id="html" value="video" checked={this.state.type === 'video'} onClick={() => {
                this.setState({ type: 'video' })
              }} />&nbsp;&nbsp;&nbsp;&nbsp;
              <label for="html">Video</label><br />
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Mô tả</label>
                <textarea ref="title" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
              <div style={{ display: this.state.type === 'image' ? "block" : 'none' }}>
                {state.file && <img src={state.filePath} className="img-thumbnail custom-thumbnail"></img>}
                <div className="upload-btn-wrapper">
                  <button className="btn btn-upload">Thêm hình ảnh</button>
                  <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                </div>
              </div>
              <div style={{ display: this.state.type === 'video' ? "block" : 'none' }}>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Đường dẫn</label>
                  <textarea ref="url" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.saveStadium.bind(this)}>Lưu</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div >
    )
  }

  renderModalEditStadium() {
    const { state } = this;
    return (
      <div className="modal show" id="modalEditStadium">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Chỉnh sửa thư viện</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Mô tả</label>
                <textarea ref="title-edit" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
              <div style={{ display: this.state.type === 'image' ? "block" : 'none' }}>
                <img ref="imgEditStadium" src={state.filePath} className="img-thumbnail custom-thumbnail"></img>
                <div className="upload-btn-wrapper">
                  <button className="btn btn-upload">Thêm hình ảnh</button>
                  <input type="file" name="myfile" onChange={this.onChangeFile.bind(this)} accept="image/jpg, image/jpeg, image/png" />
                </div>
              </div>
              <div style={{ display: this.state.type === 'video' ? "block" : 'none' }}>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Đường dẫn</label>
                  <textarea ref="url-edit" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.editStadium.bind(this)} data-dismiss="modal">Chỉnh sửa</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div >
    )
  }

  renderModalRemoveStadium() {
    return (
      <div className="modal" id="modalRemoveStadium">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Xác nhận xóa</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              Bạn có chắc chắn muốn xóa?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={this.removeStadium.bind(this)} data-dismiss="modal">Đồng ý</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
            </div>

          </div>
        </div>
      </div>
    )
  }

  convertImage = (url) => {
    if (!url || url.includes('http')) {
      return url
    } else {
      return `${DOMAIN}${url}`
    }
  }

  render() {
    const { state } = this;
    return (
      <div className="dashboard-wrapper">
        {this.renderModalRemoveStadium()}
        {this.renderModalEditStadium()}
        {this.renderModal()}
        <NotificationContainer />
        <div className="container-fluid  dashboard-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="page-header" id="top">
                <div className="page-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Thư viện</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card">
                <h5 className="card-header">Quản lý thư viện</h5>
                <div className="card-body border-top">
                  <div><button className="btn btn-success margin-bottom" data-toggle="modal" data-target="#modalCreateStadium">Thêm mới</button></div>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>Ngày Tạo</th>
                                  <th>Hình Ảnh</th>
                                  <th>Mô Tả</th>
                                  <th>Chức Năng</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.stadiumsList.map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{moment(value.created).format("DD-MM-YYYY")}</td>
                                      <td>
                                        <img className="img-table" src={this.convertImage(value.url)}></img>
                                      </td>
                                      <td>{value.title}</td>
                                      <td>
                                        <button type="button" className="btn btn-warning margin-right" data-toggle="modal" data-target="#modalEditStadium" onClick={() => {
                                          this.refs['title-edit'].value = value.title;
                                          this.refs['url-edit'].value = value.url;
                                          this.setState({ type: value.typeGallery })
                                          this.refs['imgEditStadium'].src = this.convertImage(value.url);
                                          this.id = value._id;
                                          this.image = value.image;
                                        }}>Chỉnh Sửa</button>
                                        <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveStadium" onClick={() => {
                                          this.image = value.image;
                                          this.id = value._id;
                                        }}>Xóa</button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}