import React, { Component } from 'react';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
export default class ClientPage extends Component {
    render() {
        return (
            <div className="site-wrap">
                <Header></Header>
                <Body></Body>
                <Footer></Footer>
            </div>
        );
    }
}