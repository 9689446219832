import React, { Component } from 'react';
import PaginationBt4 from '../../../Shared/PaginationBt4';
import { DOMAIN_MAIN, DOMAIN } from '../../../../constants/requestUrl';
import { apiRequestGet, apiRequestPost, apiRequestGetAuthen } from '../../../../utils/requestHelper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';

export default class Registers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registerList: [],
            totalPage: 0,
            currentPage: 1,
        }
        this.id = '';
    }

    handlePageChange(pageNumber) {
        window.location.href = `/admin/dang-ky-moi/${pageNumber}`;
        this.getRegisterList(pageNumber);
        this.setState({
            currentPage: pageNumber
        })
    }

    async removeRegister() {
        return await apiRequestPost(`${DOMAIN_MAIN}/register/remove`, JSON.stringify({ id: this.id })).then(result => {
            if (result && result.error) NotificationManager.error("Xóa không thành công", 'Lỗi', 2000);
            else {
                this.getRegisterList(this.state.currentPage);
                NotificationManager.success("Xóa thành công", 'Thành công', 2000);
            }
        });
    }

    getRegisterList(page) {
        apiRequestGetAuthen(`${DOMAIN_MAIN}/registers/${page}`).then(result => {
            if (result.error) NotificationManager.error("Không thể lấy dữ liệu", 'Lỗi', 2000);
            else this.setState({ registerList: result.registerList, totalPage: result.total })
        });
    }

    componentDidMount() {
        const { props } = this;
        if (!props.match.params.page) {
            this.getRegisterList(1);
        } else {
            this.getRegisterList(props.match.params.page);
            this.setState({
                currentPage: Number(props.match.params.page)
            })
        }
    }

    renderModalRemoveRegister() {
        return (
            <div className="modal" id="modalRemoveNew">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Xác nhận xóa</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            Bạn có chắc chắn muốn xóa?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={this.removeRegister.bind(this)} data-dismiss="modal">Đồng ý</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Tắt</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { state } = this;
        return (
            <div className="dashboard-wrapper">
                {this.renderModalRemoveRegister()}
                <NotificationContainer />
                <div className="container-fluid  dashboard-content">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header" id="top">
                                <div className="page-breadcrumb">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/" className="breadcrumb-link">Trang chủ</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Tin tức</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                                <h5 className="card-header">Quản lý tin tức</h5>
                                <div className="card-body border-top">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="example2" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Phụ Huynh</th>
                                                                    <th>Học Sinh</th>
                                                                    <th>Tuổi</th>
                                                                    <th>Địa Chỉ</th>
                                                                    <th>Email</th>
                                                                    <th>Số Điện Thoại</th>
                                                                    <th>Cơ sở</th>
                                                                    <th>Thời gian</th>
                                                                    <th>Ngày Đăng Ký</th>
                                                                    <th>Chức Năng</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.registerList.map((rgl, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{rgl.parentName}</td>
                                                                            <td>{rgl.studentName}</td>
                                                                            <td>{rgl.age}</td>
                                                                            <td>{rgl.address}</td>
                                                                            <td>{rgl.email}</td>
                                                                            <td>{rgl.phoneNumber}</td>
                                                                            <td>{rgl.stadium}</td>
                                                                            <td>{rgl.date}</td>
                                                                            <td>{moment(rgl.created).format("DD/MM/YYYY HH:mm")}</td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#modalRemoveNew" onClick={() => {
                                                                                    this.id = rgl._id;
                                                                                }}>Xóa</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                            <div className="pagination-highlights">
                                                {state.totalPage > 0 && <PaginationBt4 total={state.totalPage}
                                                    currentPage={state.currentPage}
                                                    itemsCountPerPage={10}
                                                    handlePageChange={this.handlePageChange.bind(this)}
                                                >
                                                </PaginationBt4>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}